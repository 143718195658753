/* .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    height: unset;
    overflow: auto;
    background-color: rgb(235 235 235 / .9);
    display: none;
    z-index: 9999999;
} */

.modalContainer.medium {
  background-color: #ffffff;
  width: 100%;
  max-width: 700px;
  margin: auto;
  position: relative;
  padding: 35px 50px;
  box-sizing: border-box;
}

.modalContainer .closeModal {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  color: #f56666;
  background-color: rgb(245 102 102 / 12%);
  border: unset;
  outline: none;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.modalContainer .closeModal:hover {
  color: #ffffff;
  background-color: #f56666;
}

.modalContainer .modalTitle h1 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.modalContainer .search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  width: 330px;
  margin: 25px auto 25px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalContainer ::placeholder {
  color: #b0b0b0;
}

.modalContainer .search input {
  font-size: 16px;
  line-height: 10px;
  color: #000000;
  padding: 10px 15px;
  border: unset;
  outline: none;
  width: 100%;
}

.modalContainer .search button {
  height: 33px;
  width: 33px;
  color: #ffffff;
  background: #388cef;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.modalContainer .search button:hover {
  opacity: 0.8;
}

.modalContent ul {
  list-style: none;
  margin: 0;
  padding: 0 15px 0 0;
  max-height: 440px;
  overflow-x: auto;
}

.modalContent ul::-webkit-scrollbar {
  width: 20px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
}

.modalContent ul::-webkit-scrollbar-thumb {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
}

.modalContent ul::-webkit-scrollbar-thumb:hover {
  background: #8ac04a;
  border: 1px solid #7bae3e;
}

.modalContent ul.exclude::-webkit-scrollbar-thumb:hover {
  background: #f56666;
  border: 1px solid #ea5252;
}

.modalContent ul li {
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.modalContent ul li:not(:last-child) {
  margin-bottom: 10px;
}

.modalContent ul li.selected,
.modalContent ul li:hover {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #7bae3e;
}

.modalContent ul.exclude li.selected,
.modalContent ul.exclude li:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #ea5252;
}

.modalContent ul li > span {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.modalContainer .checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 16px;
}

.modalContainer .checkbox input {
  display: none;
}

.modalContainer .checkmark {
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.modalContent ul li.selected .checkmark,
.modalContent ul li:hover .checkmark {
  background-color: #ffffff;
  border: 1px solid #7bae3e;
}

.modalContent ul.exclude li.selected .checkmark,
.modalContent ul.exclude li:hover .checkmark {
  background-color: #ffffff;
  border: 1px solid #ea5252;
}

.modalContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.modalContainer .checkbox input ~ .checkmark:after {
  display: block;
}

.modalContainer .checkbox .checkmark:after {
  left: 12px;
  top: 9px;
  width: 5px;
  height: 12px;
  border: solid #e7e5e5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.modalContent ul li.selected .checkmark:after,
.modalContent ul li:hover .checkmark:after {
  border: solid #8ac04a;
  border-width: 0 3px 3px 0;
}

.modalContent ul.exclude li.selected .checkmark:after,
.modalContent ul.exclude li:hover .checkmark:after {
  border: solid #ea5252;
  border-width: 0 3px 3px 0;
}

.modalContainer .actionBtn {
  text-align: center;
  margin-top: 20px;
}

.modalContainer.medium .actionBtn button {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #388cef;
  border-radius: 3px;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
}

.modalContainer .actionBtn button:hover {
  opacity: 0.8;
}

.modalContainer .excludeActionBtn {
  text-align: center;
  margin-top: 20px;
}

.modalContainer.medium .excludeActionBtn button {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #f56666;
  border-radius: 3px;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
}

.modalContainer .excludeActionBtn button:hover {
  opacity: 0.8;
}
