.modal { 
    position: fixed; 
    top: 0; 
    bottom: 0; 
    right: 0; 
    left: 0; 
    padding-top: 100px; 
    padding-bottom: 100px; 
    width: 100%; 
    height: unset; 
    overflow: auto; 
    background-color: rgb(235 235 235 / .9); 
    display: none; 
    z-index: 9999999; 
} 

.modalContainer { 
    background-color: #FFFFFF; 
    width: 100%; 
    /* max-width: 500px;  */
	margin: auto; 
    position: relative; 
    padding: 50px; 
    box-sizing: border-box;
}

.closeModal { 
    font-weight: 900; 
    font-size: 16px; 
    line-height: 18px; 
    color: #F56666; 
    background-color: rgb(245 102 102 / 12%); 
    border: unset; 
    outline: none; 
    width: 35px; 
    height: 35px; 
    position: absolute; 
    top: 0; 
    right: 0; 
	cursor: pointer; 
 } 

.closeModal:hover { 
    color: #ffffff; 
    background-color: #F56666; 
} 

.modalTitle h1 { 
    font-size: 20px; 
    line-height: 32px; 
    text-align: center; 
    color: #000000; 
    margin: 0; 
 } 


 .actionBtn { 
	 text-align: center; 
	 margin-top: 20px; 
 } 

 .actionBtn button { 
	 font-size: 16px; 
	 line-height: 19px; 
	 text-transform: uppercase; 
	 color: #ffffff; 
	 border-radius: 3px; 
	 border: none; 
	 padding: 15px 30px; 
	 cursor: pointer; 
	 box-sizing: border-box; 
 } 

.btnOk {
	background: #388CEF;
	border: 1px solid #388CEF;
	margin-right: 5px;
}

.btnUndo {
	background: #DDDDDD;
	border: 1px solid #DDDDDD;
}

 .actionBtn button:hover { 
	opacity: .8; 
} 