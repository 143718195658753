.modalContainer {
  display: flex;
  padding: 2rem 4rem;
  align-items: center;
  flex-direction: column;
}

.closeButton {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  border-radius: none !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.logoutText {
  margin: 1rem 0 2rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}
