.main-content {
  max-width: 1440px;
  margin: 30px auto 100px auto;
  padding-right: 20px;
  padding-left: 20px;
}

.contentHeader {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  width: 100%;
}

.topHeaderContainer.links-page {
margin-bottom: 10px;
}

.topHeaderContainer {
margin-bottom: 30px;
padding-left: 30px;
}

.createUser {
background-color: #ffffff;
padding: 60px 0;
}

.createUserHeaderContent {
  text-align: center;
  max-width: 640px;
  margin: auto;
}

.createUserHeaderContent h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #272828;
  margin: 0 0 20px;
}

.createUserHeaderContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7D7D7D;
  margin: 0;
}

.inputContainer {
  margin-top: 50px;
margin-bottom: 70px;
padding: 0 30px;
}

.inputContainer .inputRow {
display: grid;
grid-column-gap: 25px;
}

.inputContainer .inputRow {
grid-template-columns: repeat(2,1fr);
}

.inputContainer > div:not(:last-child) {
  margin-bottom: 20px;
}

.inputContainer .inputGroup > * {
font-family: 'Rubik', sans-serif;
  font-weight: 400;
font-size: 20px;
  line-height: 24px;
color: #272828;
display: block;
}

.inputContainer .inputGroup input {
  height: 60px;
}

.inputContainer .inputGroup input,
.inputContainer .inputGroup textarea {
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 3px;
  padding: 18px 20px;
  width: -webkit-fill-available;
outline: none;
}

::placeholder {
color: #B7B8B7;
}

.inputContainer .inputActionButton {
  text-align: center;
margin-top: 25px;
}

.chooseServices {
  padding: 50px 30px;
border-top: 1px solid #E7E7E7;
border-bottom: 1px solid #E7E7E7;
}

.chooseServices p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #272828;
  margin: 0 0 20px 0;
}

.inputNotify {
  max-width: 460px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
text-align: center;
}

.inputNotify h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
margin-top: 25px;
margin-bottom: 5px;
}

.inputNotify p {
font-weight: 300;
font-size: 16px;
line-height: 25px;
color: #7D7D7D;
margin: 0;
}

.switch {
position: relative;
display: inline-block;
width: 115px;
height: 60px;
}

.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: #F4F3F3;
border: 1px solid #E0E0E0;
-webkit-transition: .4s;
transition: .4s;
}

.switch input:checked + .slider:before {
left: 33px;
}

.slider:before {
position: absolute;
content: "";
height: 50px;
width: 50px;
left: 4px;
bottom: 3px;
background-color: #ffffff;
border: 1px solid #EBEBEB;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
  background-color: #8BC334;
  border: 1px solid #75AC20;
}

input:focus + .slider {
box-shadow: 0 0 1px #8BC334;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

.selectedServices {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0 0 25px 0;
}

.selectedServices li {
  font-size: 20px;
  line-height: 32px;
  color: #8F5FE8;
  background-color: #e9dffa;
  border-radius: 3px;
  padding: 10px 15px;
  margin-bottom: 5px;
display: flex;
  align-items: center;
cursor: pointer;
}

.selectedServices li:hover {
  color: #ffffff;
  background-color: #8F5FE8;
}

.selectedServices li * {
margin-left: 5px;
}

.selectedServices li:not(:last-child) {
  margin-right: 5px;
}

.inputActionButton {
  text-align: center;
}

.blueButton {
color: #388CEF;
background: #ECF4FE;
border: 1px solid #C8DFFB;
}

.blueButton:hover {
color: #ffffff;
background: #388CEF;
border: 1px solid #388CEF;
}


.removeFile {
color: #F56666;
background: #FDE0E0;
border: 1px solid rgba(245, 102, 102, 0.2);
display: inline-flex;
align-items:center;
margin-left: 7px;
}

.removeFile:hover { 
color: #ffffff;
background: #F56666;
border: 1px solid #F56666;
}

.removeFile * {
margin-left: 5px;
}

.removeFile,
.blueButton {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  padding: 15px 20px;
}

.btnSave button {
font-size: 20px;
color: #F9F9F9;
background: #388CEF;
  border: #388CEF;
width: 545px;
height: 100px;
display: flex;
align-items:center;
justify-content: center;
margin: 40px auto 0 auto;
padding: 0;
cursor: pointer;
border-radius: 6px;
}

.btnSave button:hover {
color: #388CEF;
background: #ECF4FE;
border: 1px solid #C8DFFB;
}

.deleteUser {
text-align: center;
margin-top: 40px;
}


.inputGroupLabel {
  color: #F56666!important;
  margin-top: 0.25rem;
}