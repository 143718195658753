 .modal { 
     position: fixed; 
     top: 0; 
     bottom: 0; 
     right: 0; 
     left: 0; 
     padding-top: 100px; 
     padding-bottom: 100px; 
     width: 100%; 
     height: unset; 
     overflow: auto; 
     background-color: rgb(235 235 235 / .9); 
     display: none; 
     z-index: 9999999; 
 } 

 .modalContainer.medium { 
     background-color: #FFFFFF; 
     width: 100%; 
     max-width: 700px; 
	 margin: auto; 
     position: relative; 
     padding: 35px 50px; 
     box-sizing: border-box; 
 } 

 .modalContainer .closeModal { 
     font-weight: 900; 
     font-size: 16px; 
     line-height: 18px; 
     color: #F56666; 
     background-color: rgb(245 102 102 / 12%); 
     border: unset; 
     outline: none; 
     width: 35px; 
     height: 35px; 
     position: absolute; 
     top: 0; 
     right: 0; 
	 cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
 } 

 .modalContainer .closeModal:hover { 
     color: #ffffff; 
     background-color: #F56666; 
 } 

 .modalContainer .modalTitle h1 { 
	 font-weight: 400; 
     font-size: 20px; 
     line-height: 32px; 
     text-align: center; 
     color: #000000; 
     margin: 0; 
 } 

 .modalContainer .search { 
     background: #FFFFFF; 
     border: 1px solid #EBEBEB; 
     box-sizing: border-box; 
     border-radius: 3px; 
     padding: 2px 4px 2px 2px; 
     width: 330px; 
	 margin: 25px auto 25px auto; 
	 display: flex; 
     align-items: center; 
     justify-content: space-between; 
 } 

 .modalContainer ::placeholder { 
	 color: #B0B0B0; 
 } 

 .modalContainer .search input { 
     font-size: 16px; 
     line-height: 10px; 
     color: #000000; 
     padding: 10px 15px; 
     border: unset; 
	 outline: none; 
	 width: 100%; 
 } 

 .modalContainer .search button { 
     height: 33px; 
     width: 33px; 
	 color: #ffffff; 
	 background: #388CEF; 
     border: unset; 
     border-radius: 1px; 
     cursor: pointer; 
	 outline: none; 
 } 

 .modalContainer .search button:hover { 
	 opacity: .8; 
 } 

 .modalContainer .modalContent ul { 
     list-style: none; 
     margin: 0; 
     padding: 0 15px 0 0; 
	 max-height: 440px; 
     overflow-x: auto; 
 } 

 .modalContainer .modalContent ul::-webkit-scrollbar { 
	 width: 20px; 
	 background: #F9F9F9; 
	 border: 1px solid #EBEBEB; 
	 box-sizing: border-box; 
	 border-radius: 3px; 
 } 

 .modalContainer .modalContent ul::-webkit-scrollbar-thumb { 
	 background: #FFFFFF; 
	 border: 1px solid #EBEBEB; 
	 box-sizing: border-box; 
	 border-radius: 3px; 
 } 

 .modalContainer .modalContent ul li { 
     font-size: 16px; 
     line-height: 32px; 
     color: #B0B0B0; 
     background: #F9F9F9; 
     border: 1px solid #EBEBEB; 
     box-sizing: border-box; 
     border-radius: 3px; 
     padding: 10px 15px; 
     display: flex; 
     align-items: center; 
     justify-content: space-between; 
	 cursor: pointer; 
 } 

.modalContainer .modalContent ul li button {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #f57f7f;
	background: #FFE9E9;
	border: 1px solid rgba(138, 192, 74, 0.2);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
	cursor: pointer;
	display: flex;
    align-items: end;
}

.modalContainer .modalContent ul li button:hover {
    color: #ffffff;
    background: #F56666;
    border: 1px solid #F56666;
}

 .modalContainer .modalContent ul li button svg { 
	 margin-left: 5px; 
 } 

 .modalContainer .modalContent ul li:not(:last-child) { 
	 margin-bottom: 10px; 
 } 

 .modalContainer .actionBtn { 
	 text-align: center; 
	 margin-top: 20px; 
 } 

 .modalContainer .actionBtn button { 
	 font-size: 16px; 
	 line-height: 19px; 
	 text-transform: uppercase; 
	 color: #F9F9F9; 
	 background: #388CEF; 
	 border-radius: 3px; 
	 border: none; 
	 padding: 15px 30px; 
	 cursor: pointer; 
 } 

 .modalContainer .actionBtn button:hover { 
	 opacity: .8; 
 } 