.main-content {
  max-width: 1440px;
  margin: 30px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;
}

.search {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  width: 330px;
}

.search input {
font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: #222222;
  padding: 10px 15px;
  border: unset;
outline: none;
}

::placeholder {
color: #B0B0B0;
}

.search button {
  height: 33px;
  width: 33px;
color: #ffffff;
background: #8AC04A;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
outline: none;
}

.search button:hover {
opacity: .8;
}

.pagination {
padding: 30px 30px 0 30px;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}

.pagination a {
font-size: 16px;
  width: 68px;
  height: 60px;
color: #222222;
  background-color: #F9F9F9;
border: 1px solid #EBEBEB;
box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #8AC04A;
}

.documentsList {
  background-color: #ffffff;
  padding-bottom: 30px;
}

.filterContainer {
  padding: 40px 35px 35px;
}

.filterContainer .label {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  display: block;	
}

.filterContainer .actions button {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #B0B0B0;
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 15px;
cursor: pointer;
}

.filterContainer .actions button:hover,
.filterContainer .actions button.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #7BAE3E;
}

.filterByServices {
margin-top: 40px;
}

.actions button:not(:last-child) {
  margin-right: 5px;
}

.actions button * { 
margin-right: 8px;
}

.fileList .fileListCol {
  display: flex;
padding: 20px;
}

.fileList .fileListHeader .fileListCol {
  background-color: #388CEF;
align-items: center;
}

.fileList .fileListBody > .fileListCol {
border-bottom: 1px solid #E7E7E7;
}

.fileList .fileListCol >  li:nth-child(1) {
width: 4%;
}

.fileList .fileListCol >  li:nth-child(2) {
width: 16%;
}

.fileList .fileListCol >  li:nth-child(3) {
width: 18%;
}

.fileList .fileListCol >  li:nth-child(4) {
width: 12%;
}

.fileList .fileListCol >  li:nth-child(5) {
width: 18%;
}

.fileList .fileListCol >  li:nth-child(6) {
width: 26%;
}  

.fileList .fileListCol >  li:last-child {
width: 6%;
height: 50px;
text-align: right;
position: relative;
}

.fileList .fileListHeader > ul > li {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
cursor: pointer;
display: flex;
  align-items: center;
}

.fileList ul li .seeAll {
margin-top: 15px;
}

.fileList ul li .seeAll a {
  font-weight: 400;
  color: #B0B0B0;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.fileList ul li .seeAll a:hover {
color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;	
}

.fileList ul li .seeAll a > * {
margin-left: 10px;
}

.fileList .fileListHeader ul > li > svg {
  margin-left: 4px;
}

.fileList .fileListHeader ul > li button:hover,
.fileList .fileListHeader ul > li button.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #8AC04A;
}

.fileList .fileListBody .fileListCol > li {
font-weight: 300;
font-size: 16px;
line-height: 25px;
color: #7D7D7D;
  word-break: break-all; 
} 

.fileList .fileListCol > li:not(:last-child) {
padding-right: 10px;
}

.fileList .fileListHeader .fileListCol li:last-child button {
  color: #ffffff;
  background: #449aff; 
  border: 1px solid #1E73D6;
}

.fileList .fileListCol li:last-child button {
  font-size: 19px;
  color: #B0B0B0;
  background: #F9F9F9; 
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileList .fileListHeader .fileListCol li:last-child button:hover,
.fileList .fileListHeader .fileListCol li:last-child button.active {
  color: #449aff;
  background: #ffffff; 
  border: 1px solid #ffffff;
}

.fileList .fileListCol li:last-child button:hover,
.fileList .fileListCol li:last-child button.active {
color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;
}

.fileList .fileListCol li:last-child button.active {
border-radius: 3px 3px 0 0;
}

.fileList .fileListCol button.active ~ ul {
display: block;
}

.fileList .fileListCol .settingMenu {
  position: absolute;
  top: 95%;
  right: 0;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;
  box-sizing: border-box;
  border-radius: 3px 0px 3px 3px;
  width: 180px;
  /* display: none; */
z-index: 9;
}

.fileList .fileListCol .settingMenu li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.fileList .fileListCol .settingMenu li:hover {
opacity: .8;
}

.fileList .fileListCol .settingMenu li:hover {
opacity: .8;
}

.fileList .fileListCol .settingMenu li:not(:last-child) a {
border-bottom: 1px solid #7BAE3E;
}

.fileList .fileListCol .settingMenu li a > * {
margin-right: 8px;
}

.fileList .serviceName {
  display: flex;
}

.iconContainer {
margin-right: 10px;
}

.fileList .fileIcon {
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  font-size: 24px;
  color: #B0B0B0;
}

.fileList .checkbox {
display: inline-block;
position: relative;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.fileList .fileListHeader .checkmark {
border: 1px solid #1E73D6;
}

.fileList .checkmark {
border: 1px solid #EBEBEB;
box-sizing: border-box;
border-radius: 3px;
width: 30px;
height: 30px;
display: inline-block;
}

.fileList .fileListHeader .checkbox:hover .checkmark,
.fileList .fileListHeader .checkbox.selectedAll .checkmark {
background-color: #ffffff;
border: 1px solid #ffffff;
}

.fileList .fileListHeader .checkbox .checkmark {
background-color: #2e82e3;
}

.fileList .fileListBody .selected .checkbox .checkmark, 
.fileList .fileListBody .fileListCol:hover .checkbox .checkmark {
  background-color: #388CEF;
}

.fileList .checkmark:after {
content: "";
position: absolute;
}

.fileListBody > ul:hover,
.fileListBody > ul.selected {
background-color: rgba(56, 140, 239, 0.05);
cursor: pointer;
}

.fileList .fileListBody ul:hover .fileIcon,
.fileList .fileListBody ul.selected .fileIcon {
color: #ffffff;
background: #8AC04A;
border: 1px solid #7BAE3E;
}

.fileList .fileListBody ul:hover .fileIcon svg,
.fileList .fileListBody ul.selected .fileIcon svg {
color: #ffffff;
}

.fileList .fileListBody .fileIcon svg {
color: #B0B0B0;
}

.fileList .fileListHeader .checkbox.selectedAll .checkmark:after,
.fileList .fileListHeader .checkbox:hover .checkmark:after {
border: solid #388cef;
border-width: 0 3px 3px 0;
}

.fileList .checkbox .checkmark:after {
  left: 11px;
  top: 6px;
  width: 5px;
  height: 12px;
border: solid #E7E5E5;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.fileList .fileListHeader .checkbox .checkmark:after {
  border: solid #479cff;
  border-width: 0 3px 3px 0;
}

.fileList .fileListBody .fileListCol:hover .checkmark:after, 
.fileList .fileListBody .selected .checkmark:after {
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
}

.filterByServices ul {
  display: flex;
  flex-wrap: wrap;
}

.filterByServices ul li {
  font-size: 16px;
  line-height: 32px;
  color: #8AC04A;
  background-color: #e8f2db;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
border-radius: 3px;
display: flex;
  align-items: center;
cursor: pointer;
}

.filterByServices ul li:hover {
color: #ffffff;
  background-color: #8AC04A;
}

.filterByServices ul li.showMore {
  color: #388CEF;
  background-color: #d7e8fc;
}

.filterByServices ul li.showMore:hover {
  color: #ffffff;
  background-color: #388CEF;
}

.filterByServices ul li.clearAll {
  color: #F56666;
  background-color: #fde0e0;
}

.filterByServices ul li.clearAll:hover {
  color: #ffffff;
  background-color: #F56666;
}

.filterByServices ul li > * {
margin-left: 5px;
}

.filterByType ul {
display: flex;
flex-wrap: wrap;
}

.filterByType ul li.clearAll {
color: #F56666;
background-color: #FDE0E0;
}

.filterByType ul li.clearAll:hover {
color: #ffffff;
background-color: #F56666;
}

.filterByType ul li.clearAll .fa-times-circle {
font-size: 24px;
}

.filterByType ul li {
  position: relative;
color: #8AC04A;
  background-color: #E8F2DB;
  border-radius: 3px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
cursor: pointer;
}

.filterByType ul li:hover {
color: #ffffff;
  background-color: #8AC04A;
}

.filterByType ul li:not(:last-child) {
margin-right: 10px;
}

.filterByType ul li > div {
text-align: center;
}

.filterByType ul li span {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: block;
margin-top: 5px;
}

.filterByType ul li:not(.clearAll) i:nth-child(1) {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 14px;
}

.filterByType ul li i:nth-child(2) {
  font-size: 24px;
  line-height: 28px;
margin-top: 5px;
}

@media screen and (min-width: 1024px) {
  
.iconContainer {
  margin-right: 15px;
}

}

@media screen and (min-width: 1100px) {

.fileList .fileListHeader > ul > li {
  font-size: 20px;
  line-height: 28px;
}

}


@media screen and (min-width: 1200px) {

.fileList .fileListCol >  li:nth-child(1) {
  width: 3%;
}
.fileList .fileListCol >  li:nth-child(2) {
  width: 20%;
}
.fileList .fileListCol >  li:nth-child(3) {
  width: 15%;
}
.fileList .fileListCol >  li:nth-child(4) {
  width: 10%;
}

.fileList .fileListCol >  li:nth-child(5) {
  width: 17%;
}
.fileList .fileListCol >  li:nth-child(6) {
  width: 30%;
}
.fileList .fileListCol >  li:last-child {
  width: 5%;
}	

}
