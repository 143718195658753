.main-content {
    max-width: 1440px;
    margin: 30px auto 100px auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .nqaDocumenTabHeader {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 10px;
  margin-bottom: 10px;
  }
  
  .nqaDocumenTabHeader div {
  font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  color: rgb(34 34 34 / 30%);
    background: #FFFFFF;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .nqaDocumenTabHeader .tabActive {
    color: #ffffff;
    background: #8AC04A;
  }
  
  .specificDocumentTabContent .chooseNQADocument,
  .specificDocumentTabContent .chooseDocumentType,
  .specificDocumentTabContent {
  display: none;
  }
  
  .specificDocumentTabContent .chooseNQADocument {
  margin-top: 10px; 
  }
  
  .nqaDocumentContent {
  background-color: #ffffff;
  }
  
  .nqaDocument h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #272828;
  }
  
  .nqaDocument p {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #7D7D7D;
    margin: 15px auto 55px auto;
    max-width: 640px;
  }
  
  .nqaDocument > div {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 25px;
  padding-right: 30px;
    padding-left: 30px;
  }
  
  .nqaDocument > .webinar {
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .nqaDocument > div:not(:last-child) {
  margin-bottom: 25px;
  }
  
  .nqaDocument > div > ul {
  display: grid;
  } 
  
  .nqaDocument > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
  }
  
  .nqaDocument > div > ul li {
  font-size: 16px;
  line-height: 25px;
  color: #B0B0B0;	
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  }
  
  .nqaDocument .serviceTypes > ul li:hover,
  .nqaDocument .chooseDocumentType > ul li:hover,
  .nqaDocument .serviceTypes > ul li.active,
  .nqaDocument .chooseDocumentType > ul li.active {
  color: #ffffff;	
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;;
  }
  
  .nqaDocument > div > ul li:hover svg,
  .nqaDocument > div > ul li.active svg {
  fill: #ffffff;
  }
  
  .nqaDocument .chooseNQADocument ul {
  grid-template-columns: repeat(7,1fr);
  grid-column-gap: 4px;	
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(1):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(1).active {
  color: #ffffff;
  background-color: #61a447;
  border: 1px solid #61a447;
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(2):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(2).active {
  color: #ffffff;
  background-color: #fbd539;
  border: 1px solid #fbd539;
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(3):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(3).active {
  color: #ffffff;
  background-color: #e395a5;
  border: 1px solid #e395a5;
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(4):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(4).active {
  color: #ffffff;
  background-color: #accf6b;
  border: 1px solid #accf6b;
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(5):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(5).active {
  color: #ffffff;
  background-color: #f3a23e;
  border: 1px solid #f3a23e;
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(6):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(6).active {
  color: #ffffff;
  background-color: #db308f;
  border: 1px solid #db308f;
  }
  
  .nqaDocument .chooseNQADocument ul li:nth-child(7):hover,
  .nqaDocument .chooseNQADocument ul li:nth-child(7).active {
  color: #ffffff;
  background-color: #3a96cf;
  border: 1px solid #3a96cf;
  }
  
  .nqaDocument .chooseDocumentType ul {
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 4px;	
  max-width: 770px;
  }
  
  .nqaDocument .chooseDocumentType ul li svg {
  margin-right: 10px;
  }
  
  .nqaDocument .serviceTypes ul li img {
    margin-right: 15px;
  }
  
  .nqaDocument .serviceTypes ul {
  grid-template-columns: repeat(5,1fr);
  grid-column-gap: 10px;	
  }
  
  .nqaDocument .serviceTypes ul li {
    height: 90px;
  padding: 20px 22px;
  justify-content: flex-start;
  }
  
  .nqaDocument .serviceTypes ul li span {
  display: flex;
  }
  
  .nqaDocument .serviceTypes ul li:hover::before,
  .nqaDocument .serviceTypes ul li.active::before {
    content: " ";
    display: block;
    border: solid .8em #ffffff;
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    right: 4px;
    top: 4px;
  }
  
  .nqaDocument .serviceTypes ul li:hover::after,
  .nqaDocument .serviceTypes ul li.active::after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #8ac04a;
    border-width: 0 .2em .2em 0;
    position: absolute;
    right: 14px;
    top: 12px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .nqaDocument .serviceTypes ul li svg {
  fill: #B0B0B0;
  width: 45px;
  margin-right: 15px;
  }
  
  .nqaDocument .serviceTypes ul li:hover svg,
  .nqaDocument .serviceTypes ul li.active svg {
  fill: #ffffff;
  }
  
  .specificDocumentFilter {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 5px;
  padding-right: 30px;
    padding-left: 30px;
  }
  
  .specificDocumentFilter li {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #B0B0B0;
    background-color: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .specificDocumentFilter li:hover,
  .specificDocumentFilter .active {
    color: #ffffff;
    background-color: #8AC04A;
    border: 1px solid #8AC04A;
  }
  
  .nqaDocument {
    padding-top: 60px;
  }
  
  .generateFileTabContent {
  display: none;
  }
  
  .inputContainer .inputRow {
  display: grid;
  grid-column-gap: 25px;
  }
  
  .inputContainer .webinarInputRow {
    display: grid;
    grid-column-gap: 25px;
  }

  .addServiceDocu {
    padding: 0 0 60px;
  }
  
  .addServiceDocu .inputContainer .inputRow {
    grid-template-columns: repeat(3,1fr);
  }

  .addServiceDocu .inputContainer .webinarInputRow {
    grid-template-columns: repeat(2, 1fr);
  }

  .inputContainer > div:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .inputContainer .inputGroup > * {
  font-family: 'Rubik', sans-serif;
    font-weight: 400;
  font-size: 20px;
    line-height: 24px;
  color: #272828;
  display: block;
  }

  .inputContainer .inputGroup text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: rgba(248, 113, 113);
  }

  .inputContainer .inputGroup input {
    height: 60px;
  }

  .inputContainer .inputGroup select,
  .inputContainer .inputGroup input,
  .inputContainer .inputGroup textarea {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
    padding: 18px 20px;
    width: -webkit-fill-available;
  outline: none;
  }
  
  ::placeholder {
  color: #B7B8B7;
  }
  
  .addServiceDocu .inputContainer .inputFull .inputGroup .inputContent {
  height: 400px;
  }
  
  .addServiceDocu .inputContainer .inputFull .inputGroup .inputDesc {
  height: 150px;
  }
  
  .inputContainer .inputActionButton {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 455px;
  }
  
  .inputContainer .inputActionButton .chooseServicesBtn {
  margin-right: 5px;
  }
  
  .inputNotify {
    max-width: 420px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
  text-align: center;
  }
  
  .inputNotify h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #272828;
  margin-top: 25px;
  }
  
  .inputNotify p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7D7D7D;
  margin: 0;
  }
  
  .switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 60px;
  }
  
  .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
  }
  
  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F4F3F3;
  border: 1px solid #E0E0E0;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  .switch input:checked + .slider:before {
  left: 33px;
  }
  
  .slider:before {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #8BC334;
    border: 1px solid #75AC20;
  }
  
  input:focus + .slider {
  box-shadow: 0 0 1px #8BC334;
  }
  
  input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  }
  
  .slider.round {
  border-radius: 34px;
  }
  
  .slider.round:before {
  border-radius: 50%;
  }
  
  .fileOrigin {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 370px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 30px;
  margin-top: 70px;
  margin-bottom: 70px !important;
  }
  
  .fileOrigin .radioBtn {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: flex;
    align-items: center;
  cursor: pointer;
  }
  
  .fileOrigin input {
  display: none;
  }
  
  .fileOrigin .checkmark {
  position: relative;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 15px;
  }
  
  .fileOrigin input ~ .checkmark,
  .fileOrigin input:checked ~ .checkmark {
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  }
  
  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  }
  
  .fileOrigin input ~ .checkmark:after,
  .fileOrigin input:checked ~ .checkmark:after {
  display: block;
  }
  
  .fileOrigin input ~ .checkmark:after,
  .fileOrigin input:checked ~ .checkmark:after {
   top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8AC04A;
  border: 1px solid #7BAE3E;
  }
  
  .fileOrigin input ~ .checkmark:after {
  background: #ffffff;
  border: 1px solid #EBEBEB;
  }
  
  .uploadFile {
    background: #F9F9F9;
    border: 1px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 90px;
  text-align: center;
  margin-bottom: 40px !important;
  }
  
  .uploadFile span {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #7D7D7D;
  opacity: 0.4;
  display: block;
  margin-bottom: 18px;
  }
  
  /* .uploadFile label { */
    /* font-size: 16px; */
    /* line-height: 19px; */
    /* text-transform: uppercase; */
    /* color: #F9F9F9; */
    /* background: #388CEF; */
    /* border: 1px solid #388CEF; */
    /* box-sizing: border-box; */
    /* border-radius: 3px; */
    /* display: inline-block; */
    /* padding: 15px; */
  /* cursor: pointer; */
  /* } */
  
  /* .fileOrigin, */
  /* .uploadFile, */
  .uploadFile label input {
    display: none;
  }
  
  .uploadFile, 
  .inputRow, 
  .webinarInputRow, 
  .inputFull {
    /* margin-right: 30px; */
    /* margin-left: 30px; */
  }
  
  .uploadedFile.fileOriginGenerate {
  margin-top: 50px !important;
  margin-bottom: 0 !important;
  border-top: 1px solid #E7E7E7;
  border-bottom: 1px solid #E7E7E7;
  }
  
  .uploadedFile.fileOriginGenerate ul {
  /* margin-top: 35px; */
  margin-bottom: 50px;
  /* display: none; */
  }
  
  .fileOriginUpload {
  margin-top: 60px !important;
  margin-bottom: 70px !important;
  }
  
  .uploadedFile {
  /* padding-right: 30px; */
    /* padding-left: 30px; */
  /* display: none; */
  }
  
  .uploadedFile > span {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px; 
    color: #000000;
    display: block;
    text-align: center;
    margin-bottom: 60px;
  }
  
  .uploadedFile ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  padding: 20px;
    border: 1px solid #EBEBEB;
  } 
  
  .uploadedFile ul li:not(:last-child) {
    margin-bottom: 10px;
  }
  
  .blueButton {
  font-weight: 400;
    font-size: 16px;
  color: #388CEF;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
    line-height: 19px;
    text-transform: uppercase;
    outline: none;
    padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 3px;
    display: inline-flex;
    align-items: center;
  cursor: pointer;
  }
  
  .blueButton:hover {
  color: #ffffff;
  background-color: #388CEF;
  }
  
  .main-content button svg {
  margin-left: 5px;
  }
  
  .download {
  margin-right: 10px;
  }
  
  .removeFile {
  color: #F56666;
  background-color: #FDE0E0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  /* display: none; */
  }
  
  .removeFile:hover {
  color: #ffffff;
  background-color: #F56666;
  }
  
  .uploadedFile .uploadedFileContent {
    display: flex;
    align-items: center;
  }
  
  .uploadedFile .fileIcon {
    color: #ffffff;
    background: #61A447;
    border-radius: 3px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  margin-right: 15px;
  }
  
  .uploadedFile .uploadedFileContent .fileInfo span {
    font-size: 16px;
    display: block;
  }
  
  .uploadedFile .uploadedFileContent .fileInfo .fileName {
  font-weight: 400;
  line-height: 28px;
  }
  
  .uploadedFile .uploadedFileContent .fileInfo .fileSize {
  font-weight: 300;
  line-height: 25px;
  }
  .fileOriginUpload button > * {
	margin-left: 10px;
  }
  
  .addFiles {
    display: flex;
    align-items: center;
    justify-content: space-between;
  margin: 35px 0;
  }
  
  .addFiles span {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  }
  
  .chooseServices {
    margin-bottom: 0 !important;
  padding: 30px 30px 50px;
    /* border-bottom: 1px solid #EBEBEB; */
  text-align:center;
  }
  
  .chooseServices p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #272828;
    margin: 0 auto;
  }
  
  .chooseServices ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .excludeSelectedServices li {
    color: #F56666;
    background-color: #fde0e0;
  }
  
  .excludeSelectedServices li:hover {
    color: #ffffff;
    background-color: #F56666;
  }
  
  .chooseServices ul li {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    padding: 10px 15px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 3px;
  }
  
  .selectedServices li {
    color: #8F5FE8;
    background-color: #e9dffa;
  }
  
  .selectedServices li:hover {
    color: #ffffff;
    background-color: #8F5FE8;
  }
  
  .chooseServices ul li svg {
    margin-left: 8px;
  }

  .excludeSelectedServices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .excludeSelectedServices li {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #f56666;
  background-color: #fde0e0;
  padding: 10px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  }

  .excludeSelectedServices li:hover {
    color: #ffffff;
    background-color: #f56666;
  }

  .excludeSelectedServices li svg {
    margin-left: 8px;
  }

  .excludeServices .excludeSelectedServices li {
    color: #f56666;
    background-color: #fde0e0;
  }

  .excludeServices .excludeSelectedServices li:hover {
    color: #ffffff;
    background-color: #f56666;
  }

  .btnInvalid  {
    text-align:center;
    margin-top: 40px;
    }
    
  .btnInvalid  button {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F9F9F9;
    background: #F56666;
    border: 1px solid #F56666;
    border-radius: 6px;
    outline: none;
    padding: 40px;
    max-width: 700px;
    width: 100%;
  }

  .btnSave {
  text-align:center;
  margin-top: 40px;
  }
  
  .btnSave button {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F9F9F9;
    background: #388CEF;
  border: 1px solid #388CEF;
    border-radius: 6px;
    outline: none;
    padding: 40px;
    max-width: 700px;
    width: 100%;
  cursor: pointer;
  }
  
  .btnSave button:hover {
  color: #388CEF;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);	
  }

  .btnExit {
    text-align:center;
    margin-top: 40px;
  }
    
  .btnExit button {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F56666;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    outline: none;
    padding: 40px;
    max-width: 700px;
    width: 100%;
    cursor: pointer;
  }
    
  .btnExit button:hover {
    color: #F56666;
    background: #FDE0E0;
    border: 1px solid rgba(245, 102, 102, 0.2);	
  }
  
  @media screen and (min-width: 1024px) {
  
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: flex;
  }
  
  .mobileMenu {
    display: none;
  }
  
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 12px;
  }	
  
  }
  
  @media screen and (min-width: 1100px) {
  
  .nqaDocument > div > ul li {
    line-height: 32px;
  }
  
  }
  
  @media screen and (min-width: 1150px) {
  
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 15px;
  }	
  
  }
  
  @media screen and (min-width: 1225px) {
  
  .products .product {
    width: 15.8%;
  }
  
  .products .product:not(:nth-last-child(-n+3)) { 
    margin-bottom: 0;
  }
  
  }
  
  @media screen and (min-width: 1270px) {
  
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 17px;
  }	
  
  }
  
  
  @media screen and (min-width: 1440px) {
  
  
  }