.main-content {
  max-width: 1440px;
  margin: 30px auto 100px auto;
  padding-right: 20px;
  padding-left: 20px;
}

 
.fileList .fileListBody .fileListCol:hover .checkmark:after, 
.fileList .fileListBody .selected .checkmark:after {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
}

.fileList .fileListHeader .checkbox.selectedAll .checkmark:after,
.fileList .fileListHeader .checkbox:hover .checkmark:after {
	border: solid #388cef;
	border-width: 0 3px 3px 0;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li.selected {
  background-color: #96c65c;
}

.fileList .fileListHeader ul > li .serviceTypeFilter li.selected * {
	color: #FFFFFF;
}

.fileList .fileListBody > ul:hover,
.fileList .fileListBody > ul.selected {
	background-color: rgba(56, 140, 239, 0.05);
	cursor: pointer;
}

.inputContainer .webinarInputRow {
  display: grid;
  grid-column-gap: 25px;
}

.addServiceDocu .inputContainer .webinarInputRow {
  grid-template-columns: repeat(2, 1fr);
}

.uploadFile,
.inputRow,
.webinarInputRow,
.inputFull {
  margin-right: 30px;
  margin-left: 30px;
}




.documentsList {
  background-color: #ffffff;
}

.footerFileList {
padding: 30px;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}

.contentEditor{
  height: 500px;
  border: 1px solid #000000;
  border-radius: 3px;
}

.pagination a {
  font-size: 16px;
  width: 68px;
  height: 60px; 
  color: #222222;
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
  color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #8AC04A;
}

.addNewService .fourColumns {
display: grid;
  grid-template-columns: repeat(4,1fr);
}

.addServiceDocu h2, 
.addServiceDocu .fourColumns, 
.addServiceDocu .inputRow,
.addServiceDocu .inputFull,
.addNewService .uploadFile {
  margin-right: 30px;
  margin-left: 30px;
}

.serviceDetails,
/* .branding, */
.preDefinedDocuCollections,
.addServiceDocu p {
  padding-right: 30px;
  padding-left: 30px;
}

.accordionContent {
  overflow: hidden;
  /* height: auto; */
  transition: 0.6s;
}

.addNewService {
background-color: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;
}

.addNewService h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #272828;
position: relative;
}

.addNewService h2 .accordionArrow {
  background: #FFFFFF;
  border: 1px solid #E3E2E2;
  box-sizing: border-box;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: -15px;
  bottom: 0;
cursor: pointer;
}

.addNewService p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7D7D7D;
  margin: 15px auto 30px auto;
  max-width: 640px;
}

.addServiceDocu.addNewService.generateFileTabContent {
margin-top: 100px;
}

.generateFileTabContent {
display: none;
}

.addServiceDocu {
  margin-top: 10px;
  margin-bottom: 10px;
  }

.addServiceDocu .fourColumns {
grid-column-gap: 5px;	
}

.addServiceDocu .fourColumns li:hover,
.addServiceDocu .fourColumns li.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #8AC04A;
}


.servicesFilter .fourColumns li,
.addServiceDocu .fourColumns li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #B0B0B0;
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
cursor: pointer;
display: flex;
  align-items: center;
}

.addServiceDocu .fourColumns li {
  padding: 20px;
justify-content: center; 
}

.servicesFilter .fourColumns {
  grid-column-gap: 25px;
}

.servicesFilter .fourColumns li {
  padding: 25px 20px;
}

.servicesFilter .fourColumns li svg {
  fill: #B0B0B0;
width: 45px;
}

.servicesFilter .label {
  color: #000000;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-bottom: 5px;
}

.servicesFilter .fourColumns li:hover,
.servicesFilter .fourColumns .active {
color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;
}

.servicesFilter .fourColumns li:hover svg,
.servicesFilter .fourColumns .active svg {
fill: #ffffff;
}

.servicesFilter .fourColumns li span {
  display: flex;
  margin-right: 15px;
}

.chooseNQADocument,
.chooseDocumentType {
display: none;
border-bottom: 1px solid #EBEBEB;
padding-right: 25px;
padding-left: 25px;
padding-bottom: 25px;
margin-top: 10px;
margin-bottom: 25px;
}

.chooseNQADocument li,
.chooseDocumentType li {
font-size: 16px;
line-height: 25px;
color: #B0B0B0;	
background-color: #F9F9F9;
border: 1px solid #EBEBEB;
box-sizing: border-box;
border-radius: 3px;
height: 70px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
position: relative;
}

.chooseDocumentType .label {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
}

.chooseDocumentType > ul li:hover,
.chooseDocumentType > ul li.active {
color: #ffffff;	
background-color: #8AC04A;
border: 1px solid #7BAE3E;;
}

.chooseDocumentType ul {
display: grid;
grid-template-columns: repeat(3,1fr);
grid-column-gap: 4px;	
max-width: 570px;
}

.chooseDocumentType ul li svg {
margin-right: 10px;
}

.chooseNQADocument ul {
display: grid;
grid-template-columns: repeat(7,1fr);
grid-column-gap: 4px;	
}

.chooseNQADocument ul li:nth-child(1):hover,
.chooseNQADocument ul li:nth-child(1).active {
color: #ffffff;
background-color: #61a447;
border: 1px solid #61a447;
}

.chooseNQADocument ul li:nth-child(2):hover,
.chooseNQADocument ul li:nth-child(2).active {
color: #ffffff;
background-color: #fbd539;
border: 1px solid #fbd539;
}

.chooseNQADocument ul li:nth-child(3):hover,
.chooseNQADocument ul li:nth-child(3).active {
color: #ffffff;
background-color: #e395a5;
border: 1px solid #e395a5;
}

.chooseNQADocument ul li:nth-child(4):hover,
.chooseNQADocument ul li:nth-child(4).active {
color: #ffffff;
background-color: #accf6b;
border: 1px solid #accf6b;
}

.chooseNQADocument ul li:nth-child(5):hover,
.chooseNQADocument ul li:nth-child(5).active {
color: #ffffff;
background-color: #f3a23e;
border: 1px solid #f3a23e;
}

.chooseNQADocument ul li:nth-child(6):hover,
.chooseNQADocument ul li:nth-child(6).active {
color: #ffffff;
background-color: #db308f;
border: 1px solid #db308f;
}

.chooseNQADocument ul li:nth-child(7):hover,
.chooseNQADocument ul li:nth-child(7).active {
color: #ffffff;
background-color: #3a96cf;
border: 1px solid #3a96cf;
}

.inputContainer {
  margin-top: 30px;
}

.inputContainer .inputRow {
display: grid;
grid-column-gap: 25px;
}

.addServiceDocu .inputContainer .inputRow {
  grid-template-columns: repeat(3,1fr);
}

.serviceDetails .inputContainer .inputRow {
grid-template-columns: repeat(2,1fr);
}

.inputContainer > div:not(:last-child) {
  margin-bottom: 20px;
}

.inputContainer .inputGroup > * {
font-family: 'Rubik', sans-serif;
  font-weight: 400;
font-size: 20px;
  /* line-height: 24px; */
color: #272828;
display: block;
}

.inputContainer .inputGroup input {
  height: 60px;
}

.inputContainer .inputGroup input,
.inputContainer .inputGroup textarea {
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 3px;
  padding: 18px 20px;
  width: -webkit-fill-available;
outline: none;
  width: 100%;
}

::placeholder {
color: #B7B8B7;
}

.addServiceDocu .inputContainer .inputFull .inputGroup .inputContent {
height: 400px;
}

.addServiceDocu .inputContainer .inputFull .inputGroup .inputDesc {
height: 150px;
}

.serviceDetails .inputContainer .inputFull .inputGroup textarea {
height: 225px;
}

.inputContainer .inputActionButton {
  text-align: center;
margin-top: 25px;
}

.blueButton {
font-weight: 400;
font-size: 16px;
color: #388CEF;
background: rgba(56, 140, 239, 0.1);
border: 1px solid rgba(56, 140, 239, 0.2);
box-sizing: border-box;
border-radius: 3px;
cursor: pointer;
display: inline-flex;
align-items: center;
padding: 15px 20px;
text-transform: uppercase;
outline: none;
}

.blueButton:hover {
color: #ffffff;
background-color: #388CEF;	
}

.branding {
  margin-top: 10px;
}

.branding h2 {
  margin-right: 30px;
  margin-left: 30px;
}

.branding .photoUploaded {
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  padding: 20px 40px 20px 20px;
max-width: 900px;
  margin-right: auto;
  margin-left: auto;
display: flex;
  align-items: center;
  justify-content: space-between;
margin-bottom: 40px;
}

.branding .photoUploadedContent {
  display: flex;
  align-items: center;
}

.branding .photoUploadedContent img {
border: 1px solid #EBEBEB;
box-sizing: border-box;
padding: 15px;
}

.branding .photoInfo {
  margin-left: 20px;
}

.branding .photoInfo > span {
  display: block;
}

.branding .photoName {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
}

.branding .photoSize {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7D7D7D;
}

.colorBranding {
  padding-top: 60px;
  padding-right: 30px;
  padding-left: 30px;
  border-top: 1px solid #EBEBEB;
}

.colorBranding .colorRow {
  /* display: grid; */
  /* grid-template-columns: repeat(2,1fr); */
  grid-column-gap: 20px;
  max-width: 610px;
  margin-right: auto;
  margin-left: auto;
}

.colorBranding .colorRow > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  display: block;
  margin-bottom: 20px;
}

.colorBranding .colorRow .chooseColor label img {
margin-right: 15px;
}

.colorBranding .colorRow .chooseColor label {
font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #222222;
  background: #F9F9F9;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 10px;
cursor: pointer;
}

.colorResult {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.btnSaveColor {
text-align: center;
}

/* .btnSaveColor button { */
  /* font-size: 16px; */
  /* line-height: 19px; */
  /* text-transform: uppercase; */
  /* color: #F9F9F9; */
  /* background: #388CEF; */
  /* border: 1px solid #388CEF; */
  /* box-sizing: border-box; */
  /* border-radius: 3px; */
  /* display: inline-block; */
  /* padding: 15px; */
/* } */

.preDefinedDocuCollections {
margin-top: 10px;
}

.inputNotify {
margin-top: 40px;
text-align: center;
display: none;
}

.inputNotify h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
margin-top: 25px;
}

.switch {
position: relative;
display: inline-block;
width: 115px;
height: 60px;
}

.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #F4F3F3;
border: 1px solid #E0E0E0;
-webkit-transition: .4s;
transition: .4s;
}

.switch input:checked + .slider:before {
left: 30px;
}

.slider:before {
position: absolute;
content: "";
height: 50px;
width: 50px;
left: 4px;
bottom: 3px;
background-color: #ffffff;
border: 1px solid #E0E0E0;
-webkit-transition: .4s;
transition: .4s;
}

.colorBranding input:checked + .slider {
  background-color: #343434;
  border: 1px solid #343434;
}

input:checked + .slider {
  background-color: #8BC334;
  border: 1px solid #8BC334;
}

input:focus + .slider {
box-shadow: 0 0 1px #8BC334;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

.fileOrigin {
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
max-width: 370px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 30px;
margin-top: 50px;
margin-bottom: 55px !important;
}

.fileOrigin .radioBtn {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: flex;
  align-items: center;
cursor: pointer;
}

.fileOrigin input {
display: none;
}

.fileOrigin .checkmark {
position: relative;
height: 35px;
width: 35px;
border-radius: 50%;
margin-right: 15px;
}

.fileOrigin input ~ .checkmark,
.fileOrigin input:checked ~ .checkmark {
background: #F9F9F9;
border: 1px solid #EBEBEB;
box-sizing: border-box;
}

.checkmark:after {
content: "";
position: absolute;
display: none;
}

.fileOrigin input ~ .checkmark:after,
.fileOrigin input:checked ~ .checkmark:after {
display: block;
}

.fileOrigin input ~ .checkmark:after,
.fileOrigin input:checked ~ .checkmark:after {
 top: 6px;
left: 6px;
width: 20px;
height: 20px;
border-radius: 50%;
background: #8AC04A;
border: 1px solid #7BAE3E;
}

.fileOrigin input ~ .checkmark:after {
background: #ffffff;
border: 1px solid #EBEBEB;
}


.uploadFile {
  background: #F9F9F9;
  border: 1px dashed #E0E0E0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 90px;
text-align: center;
margin-bottom: 40px !important;
}

.uploadFile span {
font-size: 30px;
line-height: 36px;
text-align: center;
color: #7D7D7D;
opacity: 0.4;
display: block;
margin-bottom: 18px;
}

/* .uploadFile label { */
  /* font-size: 16px; */
  /* line-height: 19px; */
  /* text-transform: uppercase; */
  /* color: #F9F9F9; */
  /* background: #388CEF; */
  /* border: 1px solid #388CEF; */
  /* box-sizing: border-box; */
  /* border-radius: 3px; */
  /* display: inline-block; */
  /* padding: 15px; */
/* cursor: pointer; */
/* } */

.preDefinedDocuCollections ul {
grid-column-gap: 20px;
grid-row-gap: 20px;
}

.preDefinedDocuCollections.addNewService .fourColumns {
  grid-template-columns: repeat(2,1fr);
}

.preDefinedDocuCollections ul li {
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 30px 0;	
text-align: center;
}

.preDefinedDocuCollections ul li:hover,
.preDefinedDocuCollections ul li.active {
background: #8AC04A;
border: 1px solid #8AC04A;
cursor: pointer;
}

.preDefinedDocuCollections ul li:hover span,
.preDefinedDocuCollections ul li.active span {
color: #ffffff !important;
}

.preDefinedDocuCollections ul li:hover .collectionDocu,
.preDefinedDocuCollections ul li.active .collectionDocu {
background-color: rgb(255 255 255 / 20%);
}

.preDefinedDocuCollections ul li > span {
  display: block;
  font-weight: 400;
}

.preDefinedDocuCollections ul li .collectionIcon,
.preDefinedDocuCollections ul li .collectionName,
.preDefinedDocuCollections ul li .collectionButton {
  padding-right: 12px;
  padding-left: 12px;
}

.preDefinedDocuCollections ul li .collectionIcon {
  margin-bottom: 20px;
  height: 90px;
}

.preDefinedDocuCollections ul li .collectionIcon svg {
fill: #388CEF;
  width: 80px;
}

.preDefinedDocuCollections ul li .collectionName {
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 10px;
}

.preDefinedDocuCollections ul li .collectionDocu {
  font-size: 16px;
  line-height: 12px;
  color: #8F5FE8;
  background-color: #e4daf6;
  padding: 5px;
  display: inline-block;
  margin-bottom: 20px;
}

.preDefinedDocuCollections ul li .collectionDesc {
  font-size: 16px;
  line-height: 24px;
  color: #B0B0B0;
  padding: 15px 12px;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  height: 110px;
}

.preDefinedDocuCollections ul li .collectionButton {
margin-top: 25px;
}

.preDefinedDocuCollections ul li .collectionButton button {
  font-size: 14px;
  line-height: 17px;
  border-radius: 3px;
  outline: none;
  padding: 10px 15px;
  cursor: pointer;
}

.preDefinedDocuCollections ul li:hover .collectionButton .loadDocs,
.preDefinedDocuCollections ul li.active .collectionButton .loadDocs {
color: #ffffff;
background: #388CEF;
}

.preDefinedDocuCollections ul li:hover .collectionButton .loadView,
.preDefinedDocuCollections ul li.active .collectionButton .loadView {
color: #ffffff;
background: rgba(255, 255, 255, 0.2);
border: 1px solid rgba(255, 255, 255, 0.2);
background-clip:  padding-box;
}

.preDefinedDocuCollections ul li:hover .collectionIcon svg,
.preDefinedDocuCollections ul li.active .collectionIcon svg {
fill: #ffffff;
  width: 80px;
}

.preDefinedDocuCollections ul li .collectionButton .loadDocs {
color: #388CEF;
background: rgba(56, 140, 239, 0.1);
border: 1px solid rgba(56, 140, 239, 0.2);
}
.preDefinedDocuCollections ul li .collectionButton .unLoadDocs {
color: #ffffff;
background: #FF5F5F;
border: 1px solid #FF5F5F;
}
.preDefinedDocuCollections ul li .collectionButton .loadView {
color: #B0B0B0;
background: #FFFFFF;
border: 1px solid #E8E8E8;
}

.uploadFile label input {
  display: none;
}

.uploadedFile.fileOriginGenerate {
margin-top: 50px !important;
margin-bottom: 0 !important;
border-bottom: 1px solid #E7E7E7;
}

.fileOriginGenerate ul {
margin-top: 35px;
margin-bottom: 50px;
}

.uploadedFile {
padding-right: 30px;
  padding-left: 30px;
margin-top: 60px !important;
  margin-bottom: 70px !important;
}

.uploadedFile > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  text-align: center;
  margin-bottom: 60px;
}

.uploadedFile ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #EBEBEB;
}

.uploadedFile ul li:not(:last-child) {
  margin-bottom: 10px;
}

.addNewService button svg {
margin-left: 5px;
}

.uploadedFile ul li .uploadedFileActionButtons .download {
margin-right: 5px;
}

.removeFile {
color: #F56666;
background-color: #FDE0E0;
border: 1px solid rgba(245, 102, 102, 0.2);
}

.removeFile:hover {
color: #ffffff;
background-color: #F56666;
}

.uploadedFile .uploadedFileContent {
  display: flex;
  align-items: center;
}

.uploadedFile .fileIcon {
  color: #ffffff;
  /* background: #61A447; */
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
margin-right: 15px;
}

.uploadedFile .uploadedFileContent .fileInfo span {
  font-size: 16px;
  display: block;
}

.uploadedFile .uploadedFileContent .fileInfo .fileName {
font-weight: 400;
line-height: 28px;
}

.uploadedFile .uploadedFileContent .fileInfo .fileSize {
font-weight: 300;
line-height: 25px;
}

.addFiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0;
}

.addFiles span {
font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #272828;
}

.btnSave {
text-align:center;
margin-top: 40px;
}

.btnSave button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #F9F9F9;
  background: #388CEF;
  border: 1px solid #388CEF;
  border-radius: 6px;
  outline: none;
  padding: 40px;
  max-width: 700px;
  width: 100%;
cursor: pointer;
}

.btnSave button:hover {
  color: #388CEF;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
}

@media screen and (min-width: 1024px) {

.bottomHeader .headerMenu .mainHeaderMenu {
  display: flex;
}

.mobileMenu {
  display: none;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
  font-size: 12px;
}	

}

@media screen and (min-width: 1100px) {

.servicesFilter .fourColumns li,
.addServiceDocu .fourColumns li {
  line-height: 32px;
}

}

@media screen and (min-width: 1150px) {

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
  font-size: 15px;
}	

}

@media screen and (min-width: 1225px) {

.products .product {
  width: 15.8%;
}

.products .product:not(:nth-last-child(-n+3)) { 
  margin-bottom: 0;
}

}

@media screen and (min-width: 1270px) {

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
  font-size: 17px;
}	

}


@media screen and (min-width: 1440px) {

.preDefinedDocuCollections.addNewService .fourColumns {
  grid-template-columns: repeat(4,1fr);
}

}