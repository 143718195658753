.inputGroup {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
}

.inputName {
    font-size: 16px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
}

.inputWarning {
    color: #F56666;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
}

.inputBox {
    height: 60px;
    max-width: unset;
    padding: 0.5rem 1rem;
    /* font-size: 20px; */
    margin-top: 0.5rem;
    border-radius: 3px;
    border: 1px solid #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
}

.inputTextArea {
    resize: none;
    padding: 1rem;
    font-size: 20px;
    border-radius: 3px;
    margin-top: 0.5rem;
    border: 1px solid black;
}

.passwordContainer {
    display: flex;
    position: relative;
    align-items: center;
}

.passwordInputBox {
    width: 100%;
    height: 60px;
    padding: 0.5rem 1rem;
    font-size: 20px;
    margin-top: 0.5rem;
    border-radius: 3px;
    border: 1px solid #000000;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
}

.passwordIcon {
    top: 24px;
    right: 20px;
    cursor: pointer;
    position: absolute;
}

.inputInvalid {
    border: 1px solid #F56666;
    background-color: #fef0f0;
}

@media screen and (min-width: 1024px) {
    .inputWarning, .inputName {
        font-size: 20px;
    }
}