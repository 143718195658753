.main-content {
    max-width: 1440px;
    margin: 30px auto 100px auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .modalContainer {
    background-color: #FFFFFF;
    width: 100%;
    max-width: auto!important;
  margin: auto;
    position: relative;
    padding: 35px 50px;
    box-sizing: border-box;
  }
  
  .ui.inverted.progress .bar{
    background: #8AC04A
  }
  
  .closeModal {
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    color: #F56666;
    background-color: rgb(245 102 102 / 12%);
    border: unset;
    outline: none;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 0;
    right: 0;
  cursor: pointer;
  }
  
  .closeModal:hover {
    color: #ffffff;
    background-color: #F56666;
  }
  
  .topHeaderContainer.links-page {
  margin-bottom: 10px;
  }
  
  .topHeaderContainer > * {
    width: 50%;
  }
  
  .topHeaderContainer .viewAsService {
  text-align: right;
  }
  
  .topHeaderContainer .viewAsService a {
  color: #ffffff;
  background: #8F5FE8;
  border: 1px solid #8F5FE8;
  }
  
  .topHeaderContainer .viewAsService a:hover {
  color: #ffffff;
  background: #8F5FE8;
  border: 1px solid #8F5FE8;
  opacity: .8;
  }
  
  .documentsList {
    background-color: #ffffff;
  padding-bottom: 30px;
  }
  
  .pagination a:not(:last-child) {
    margin-right: 10px;
  }
  
  .pagination {
    padding: 30px 30px 0;
  }
  
  .pagination a {
  font-size: 16px;
    width: 68px;
    height: 60px; 
  color: #222222;
    background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
    border-radius: 5px;
  }
  
  .pagination a:hover,
  .pagination a.active {
  color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #8AC04A;
  }
  
  .addNewService .fourColumns {
  display: grid;
    grid-template-columns: repeat(4,1fr);
  }
  
  .addServiceDocu h2, 
  .addServiceDocu .fourColumns, 
  .addServiceDocu .inputRow,
  .addServiceDocu .inputFull,
  .addNewService .uploadFile {
    margin-right: 30px;
    margin-left: 30px;
  }
  
  .serviceDetails,
  /* .branding, */
  .preDefinedDocuCollections,
  .addServiceDocu p {
    padding-right: 30px;
    padding-left: 30px;
  }
  
  .accordionContent {
    overflow: hidden;
    /* height: auto; */
    transition: 0.6s;
  }
  
  .addNewService {
  background-color: #ffffff;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .addNewService h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #272828;
  position: relative;
  }
  
  .addNewService h2 .accordionArrow {
    background: #FFFFFF;
    border: 1px solid #E3E2E2;
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: -15px;
    bottom: 0;
  cursor: pointer;
  }
  
  .addNewService p {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #7D7D7D;
    margin: 15px auto 30px auto;
    max-width: 640px;
  }
  
  .generateFileTabContent {
  display: none;
  }
  
  .addServiceDocu {
  margin-top: 10px;
  margin-bottom: 10px;
  }
  
  .addServiceDocu .fourColumns {
  grid-column-gap: 5px;	
  }
  
  .addServiceDocu .fourColumns li:hover,
  .addServiceDocu .fourColumns li.active {
  color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #8AC04A;
  }
  
  
  .servicesFilter .fourColumns li,
  .addServiceDocu .fourColumns li {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #B0B0B0;
    background-color: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
  cursor: pointer;
  display: flex;
    align-items: center;
  }
  
  .addServiceDocu .fourColumns li {
    padding: 20px;
  justify-content: center; 
  }
  
  .servicesFilter .fourColumns {
    grid-column-gap: 25px;
  }
  
  .servicesFilter .fourColumns li {
    padding: 25px 20px;
  }
  
  .servicesFilter .fourColumns li svg {
    fill: #B0B0B0;
  width: 45px;
  }
  
  .servicesFilter .label {
    color: #000000;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: block;
    margin-bottom: 5px;
  }
  
  .servicesFilter .fourColumns li:hover,
  .servicesFilter .fourColumns .active {
  color: #ffffff;
    background-color: #8AC04A;
    border: 1px solid #7BAE3E;
  }
  
  .servicesFilter .fourColumns li:hover svg,
  .servicesFilter .fourColumns .active svg {
  fill: #ffffff;
  }
  
  .servicesFilter .fourColumns li span {
    display: flex;
    margin-right: 15px;
  }
  
  .chooseNQADocument,
  .chooseDocumentType {
  display: none;
  border-bottom: 1px solid #EBEBEB;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 25px;
  margin-top: 10px;
  margin-bottom: 25px;
  }
  
  .chooseNQADocument li,
  .chooseDocumentType li {
  font-size: 16px;
  line-height: 25px;
  color: #B0B0B0;	
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  }
  
  .chooseDocumentType .label {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: block;
    margin-bottom: 15px;
  }
  
  .chooseDocumentType > ul li:hover,
  .chooseDocumentType > ul li.active {
  color: #ffffff;	
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;;
  }
  
  .chooseDocumentType ul {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 4px;	
  max-width: 570px;
  }
  
  .chooseDocumentType ul li svg {
  margin-right: 10px;
  }
  
  .chooseNQADocument ul {
  display: grid;
  grid-template-columns: repeat(7,1fr);
  grid-column-gap: 4px;	
  }
  
  .chooseNQADocument ul li:nth-child(1):hover,
  .chooseNQADocument ul li:nth-child(1).active {
  color: #ffffff;
  background-color: #61a447;
  border: 1px solid #61a447;
  }
  
  .chooseNQADocument ul li:nth-child(2):hover,
  .chooseNQADocument ul li:nth-child(2).active {
  color: #ffffff;
  background-color: #fbd539;
  border: 1px solid #fbd539;
  }
  
  .chooseNQADocument ul li:nth-child(3):hover,
  .chooseNQADocument ul li:nth-child(3).active {
  color: #ffffff;
  background-color: #e395a5;
  border: 1px solid #e395a5;
  }
  
  .chooseNQADocument ul li:nth-child(4):hover,
  .chooseNQADocument ul li:nth-child(4).active {
  color: #ffffff;
  background-color: #accf6b;
  border: 1px solid #accf6b;
  }
  
  .chooseNQADocument ul li:nth-child(5):hover,
  .chooseNQADocument ul li:nth-child(5).active {
  color: #ffffff;
  background-color: #f3a23e;
  border: 1px solid #f3a23e;
  }
  
  .chooseNQADocument ul li:nth-child(6):hover,
  .chooseNQADocument ul li:nth-child(6).active {
  color: #ffffff;
  background-color: #db308f;
  border: 1px solid #db308f;
  }
  
  .chooseNQADocument ul li:nth-child(7):hover,
  .chooseNQADocument ul li:nth-child(7).active {
  color: #ffffff;
  background-color: #3a96cf;
  border: 1px solid #3a96cf;
  }
  
  .inputContainer {
    margin-top: 30px;
  }
  
  .inputContainer .inputRow {
  display: grid;
  grid-column-gap: 25px;
  }
  
  .addServiceDocu .inputContainer .inputRow {
    grid-template-columns: repeat(3,1fr);
  }
  
  .serviceDetails .inputContainer .inputRow {
  grid-template-columns: repeat(2,1fr);
  }
  
  .inputContainer > div:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .inputContainer .inputGroup > * {
  font-family: 'Rubik', sans-serif;
    font-weight: 400;
  font-size: 20px;
    line-height: 24px;
  color: #272828;
  display: block;
  }
  
  .inputContainer .inputGroup input {
    height: 60px;
  }
  
  .inputContainer .inputGroup input,
  .inputContainer .inputGroup textarea {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
    padding: 18px 20px;
    width: -webkit-fill-available;
  outline: none;
  }
  
  ::placeholder {
  color: #B7B8B7;
  }
  
  .addServiceDocu .inputContainer .inputFull .inputGroup .inputContent {
  height: 400px;
  }
  
  .addServiceDocu .inputContainer .inputFull .inputGroup .inputDesc {
  height: 150px;
  }
  
  .serviceDetails .inputContainer .inputFull .inputGroup textarea {
  height: 225px;
  }
  
  .inputActionButton {
    text-align: center;
  }
  
  .blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388CEF;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
  }
  
  .blueButton:hover {
  color: #ffffff;
  background-color: #388CEF;	
  }
  
  .branding {
    margin-top: 10px;
  }
  
  
  .branding h2 {
  margin-right: 30px;
  margin-left: 30px;
  }
  
  .branding .photoUploaded {
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    padding: 20px 40px 20px 20px;
  max-width: 900px;
    margin-right: auto;
    margin-left: auto;
  display: flex;
    align-items: center;
    justify-content: space-between;
  margin-bottom: 40px;
  }
  
  .branding .photoUploadedContent {
    display: flex;
    align-items: center;
  }
  
  .branding .photoUploadedContent img {
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  padding: 15px;
  }
  
  .branding .photoInfo {
    margin-left: 20px;
  }
  
  .branding .photoInfo > span {
    display: block;
  }
  
  .branding .photoName {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #272828;
  }
  
  .branding .photoSize {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: #7D7D7D;
  }
  
  .colorBranding {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 30px;
    border-top: 1px solid #EBEBEB;
  }
  
  .colorBranding .colorRow {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 20px;
    max-width: 610px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .colorBranding .colorRow > div > span {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #272828;
    display: block;
    margin-bottom: 20px;
  }
  
  .colorBranding .colorRow .chooseColor label img {
  margin-right: 15px;
  }
  
  .colorBranding .colorRow .chooseColor label {
  font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #222222;
    background: #F9F9F9;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 10px;
  cursor: pointer;
  }
  
  .colorResult {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  
  .btnSaveColor {
  text-align: center;
  }
  
  .preDefinedDocuCollections {
  margin-top: 10px;
  }
  
  .inputNotify {
  margin-top: 40px;
  text-align: center;
  }
  
  .inputNotify h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #272828;
  margin-top: 25px;
  }
  
  .switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 60px;
  }
  
  .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
  }
  
  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F4F3F3;
  border: 1px solid #E0E0E0;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  .switch input:checked + .slider:before {
  left: 30px;
  }
  
  .slider:before {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  border: 1px solid #E0E0E0;
  -webkit-transition: .4s;
  transition: .4s;
  }
  
  .colorBranding input:checked + .slider {
    background-color: #343434;
    border: 1px solid #343434;
  }
  
  input:checked + .slider {
    background-color: #8BC334;
    border: 1px solid #8BC334;
  }
  
  input:focus + .slider {
  box-shadow: 0 0 1px #8BC334;
  }
  
  input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  }
  
  .slider.round {
  border-radius: 34px;
  }
  
  .slider.round:before {
  border-radius: 50%;
  }
  
  .fileOrigin {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 370px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 30px;
  margin-top: 50px;
  margin-bottom: 55px !important;
  }
  
  .fileOrigin .radioBtn {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: flex;
    align-items: center;
  cursor: pointer;
  }
  
  .fileOrigin input {
  display: none;
  }
  
  .fileOrigin .checkmark {
  position: relative;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 15px;
  }
  
  .fileOrigin input ~ .checkmark,
  .fileOrigin input:checked ~ .checkmark {
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  }
  
  .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  }
  
  .fileOrigin input ~ .checkmark:after,
  .fileOrigin input:checked ~ .checkmark:after {
  display: block;
  }
  
  .fileOrigin input ~ .checkmark:after,
  .fileOrigin input:checked ~ .checkmark:after {
   top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8AC04A;
  border: 1px solid #7BAE3E;
  }
  
  .fileOrigin input ~ .checkmark:after {
  background: #ffffff;
  border: 1px solid #EBEBEB;
  }
  
  
  .uploadFile {
    background: #F9F9F9;
    border: 1px dashed #E0E0E0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 90px;
  text-align: center;
  margin-bottom: 40px !important;
  }
  
  .uploadFile span {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #7D7D7D;
  opacity: 0.4;
  display: block;
  margin-bottom: 18px;
  }
  
  .preDefinedDocuCollections ul {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  }
  
  .preDefinedDocuCollections.addNewService .fourColumns {
    grid-template-columns: repeat(2,1fr);
  }
  
  .preDefinedDocuCollections ul li {
    background-color: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 30px 0;	
  text-align: center;
    position: relative;
  }
  
  .preDefinedDocuCollections ul li.active::before {
    content: " ";
    display: block;
    border: solid .8em #ffffff;
    border-radius: .8em;
    height: 0;
    width: 0;
    position: absolute;
    right: 4px;
    top: 4px;
  }
  
  .preDefinedDocuCollections ul li.active::after {
    content: " ";
    display: block;
    width: .3em;
    height: .6em;
    border: solid #8ac04a;
    border-width: 0 .2em .2em 0;
    position: absolute;
    right: 13px;
    top: 9px;
    -webkit-transform: rotate(
  45deg
  );
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(
  45deg
  );
  }
  
  .preDefinedDocuCollections ul li:hover,
  .preDefinedDocuCollections ul li.active {
  background: #8AC04A;
  border: 1px solid #8AC04A;
  cursor: pointer;
  }
  
  .preDefinedDocuCollections ul li:hover span,
  .preDefinedDocuCollections ul li.active span {
  color: #ffffff !important;
  }
  
  .preDefinedDocuCollections ul li:hover .collectionDocu,
  .preDefinedDocuCollections ul li.active .collectionDocu {
  background-color: rgb(255 255 255 / 20%);
  }
  
  .preDefinedDocuCollections ul li > span {
    display: block;
  font-weight: 400;
  }
  
  .preDefinedDocuCollections ul li .collectionIcon,
  .preDefinedDocuCollections ul li .collectionName,
  .preDefinedDocuCollections ul li .collectionButton {
  padding-right: 12px;
  padding-left: 12px;
  }
  
  .preDefinedDocuCollections ul li .collectionIcon {
    margin-bottom: 20px;
    height: 90px;
  }
  
  .preDefinedDocuCollections ul li .collectionIcon svg {
  fill: #388CEF;
    width: 80px;
  }
  
  .preDefinedDocuCollections ul li .collectionName {
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  margin-bottom: 10px;
  }
  
  .preDefinedDocuCollections ul li .collectionDocu {
    font-size: 16px;
    line-height: 12px;
    color: #8F5FE8;
    background-color: #e4daf6;
    padding: 5px;
  display: inline-block;
  margin-bottom: 20px;
  }
  
  .preDefinedDocuCollections ul li .collectionDesc {
    font-size: 16px;
    line-height: 24px;
    color: #B0B0B0;
  padding: 15px 12px;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
    height: 75px;
  }
  
  .preDefinedDocuCollections ul li .collectionButton {
  margin-top: 25px;
  }
  
  .preDefinedDocuCollections ul li .collectionButton button {
    font-size: 14px;
    line-height: 17px;
    border-radius: 3px;
    outline: none;
    padding: 10px 15px;
  cursor: pointer;
  }
  
  .preDefinedDocuCollections ul li:hover .collectionButton .loadDocs,
  .preDefinedDocuCollections ul li.active .collectionButton .loadDocs {
  color: #ffffff;
  background: #388CEF;
  }
  
  .preDefinedDocuCollections ul li:hover .collectionButton .loadView,
  .preDefinedDocuCollections ul li.active .collectionButton .loadView {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-clip:  padding-box;
  }
  
  .preDefinedDocuCollections ul li:hover .collectionIcon svg,
  .preDefinedDocuCollections ul li.active .collectionIcon svg {
  fill: #ffffff;
    width: 80px;
  }
  
  .preDefinedDocuCollections ul li .collectionButton .loadDocs {
  color: #388CEF;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  }
  .preDefinedDocuCollections ul li .collectionButton .unLoadDocs {
  color: #ffffff;
  background: #FF5F5F;
  border: 1px solid #FF5F5F;
  }
  .preDefinedDocuCollections ul li .collectionButton .loadView {
  color: #B0B0B0;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  }
  
  .uploadFile label input {
    display: none;
  }
  
  .uploadedFile.fileOriginGenerate {
  margin-top: 50px !important;
  margin-bottom: 0 !important;
  border-top: 1px solid #E7E7E7;
  border-bottom: 1px solid #E7E7E7;
  }
  
  .fileOriginGenerate ul {
  margin-top: 35px;
  margin-bottom: 50px;
  }
  
  .uploadedFile {
  padding-right: 30px;
    padding-left: 30px;
  margin-top: 60px !important;
    margin-bottom: 70px !important;
  }
  
  .uploadedFile > span {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: block;
    text-align: center;
    margin-bottom: 60px;
  }
  
  .uploadedFile ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  padding: 20px;
    border: 1px solid #EBEBEB;
  }
  
  .uploadedFile ul li:not(:last-child) {
    margin-bottom: 10px;
  }
  
  .addNewService button svg {
  margin-left: 5px;
  }
  
  .uploadedFile ul li .uploadedFileActionButtons .download {
  margin-right: 5px;
  }
  
  .removeFile {
  color: #F56666;
  background-color: #FDE0E0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  }
  
  .removeFile:hover {
  color: #ffffff;
  background-color: #F56666;
  }
  
  .uploadedFile .uploadedFileContent {
    display: flex;
    align-items: center;
  }
  
  .uploadedFile .fileIcon {
    color: #ffffff;
    background: #61A447;
    border-radius: 3px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  margin-right: 15px;
  }
  
  .uploadedFile .uploadedFileContent .fileInfo span {
    font-size: 16px;
    display: block;
  }
  
  .uploadedFile .uploadedFileContent .fileInfo .fileName {
  font-weight: 400;
  line-height: 28px;
  }
  
  .uploadedFile .uploadedFileContent .fileInfo .fileSize {
  font-weight: 300;
  line-height: 25px;
  }
  
  .addFiles {
    display: flex;
    align-items: center;
    justify-content: space-between;
  margin: 35px 0;
  }
  
  .addFiles span {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  }
  
  .btnSave {
  text-align:center;
  margin-top: 40px;
  }
  
  .btnSave button {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F9F9F9;
    background: #388CEF;
  border: 1px solid #388CEF;
    border-radius: 6px;
    outline: none;
    padding: 40px;
    max-width: 700px;
    width: 100%;
  cursor: pointer;
  }
  
  .btnSave button:hover {
    color: #388CEF;
    background: rgba(56, 140, 239, 0.1);
    border: 1px solid rgba(56, 140, 239, 0.2);
  }
  
  /* Collections */
  
  .tabHeader {
    background-color: #ffffff;
  }
  
  .tabHeader ul {
    display: grid;
    grid-template-columns: repeat(7,1fr);
  }
  
  .tabHeader ul li:not(:last-child) {
    border-right: 4px solid #f2f2f2;
  }
  
  .tabHeader ul li:hover,
  .tabHeader ul li.active {
  font-weight: 400;
  color: #222222;
  border-bottom: 4px solid #ffffff;
  }
  
  .tabHeader ul li:hover::before,
  .tabHeader ul li.active::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-370%, -50%);
  }
  
  .tabHeader ul li:nth-child(1):hover::before,
  .tabHeader ul li:nth-child(1).active::before {
   background-color: #61a447;
  }
  
  .tabHeader ul li:nth-child(2):hover::before,
  .tabHeader ul li:nth-child(2).active::before {
   background-color: #fbd539;
  }
  
  .tabHeader ul li:nth-child(3):hover::before,
  .tabHeader ul li:nth-child(3).active::before {
   background-color: #e395a5;
  }
  
  .tabHeader ul li:nth-child(4):hover::before,
  .tabHeader ul li:nth-child(4).active::before {
   background-color: #accf6b;
  }
  
  .tabHeader ul li:nth-child(5):hover::before,
  .tabHeader ul li:nth-child(5).active::before {
   background-color: #f3a23e;
  }
  
  .tabHeader ul li:nth-child(6):hover::before,
  .tabHeader ul li:nth-child(6).active::before {
   background-color: #db308f;
  }
  
  .tabHeader ul li:nth-child(7):hover::before,
  .tabHeader ul li:nth-child(7).active::before {
   background-color: #3a96cf;
  }
  
  .tabHeader ul li {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #f2f2f2;
  font-weight: 300;
  font-size: 16px;
  color: rgb(34 34 34 / 40%);
  cursor: pointer;
  position: relative;
  }
  
  .pagination {
  padding: 30px 30px 0 30px;
  }
  
  .pagination a:not(:last-child) {
    margin-right: 10px;
  }
  
  .pagination a {
  font-size: 16px;
    width: 68px;
    height: 60px;
  color: #222222;
    background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
    border-radius: 5px;
  }
  
  .pagination a:hover,
  .pagination a.active {
  color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #8AC04A;
  }
  
  .documentsList {
    background-color: #ffffff;
    padding-bottom: 50px;
  }
  
  .filterContainer {
    padding: 45px 35px 35px;
  }
  
  .filterContainer .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    display: block;	
  }
  
  .filterContainer .actions button {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #B0B0B0;
    background-color: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 5px 15px;
  cursor: pointer;
  }
  
  .filterContainer .actions button:hover,
  .filterContainer .actions button.active {
  color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;
  }
  
  .actions button:not(:last-child) {
    margin-right: 5px;
  }
  
  .actions button i { 
  font-size: 15px;
  margin-right: 8px;
  }
  
  .fileList .fileListHeader .fileListCol {
  /* background: #388CEF; */
  align-items: center;
  border-bottom-width: 1px;
  }
  
  .fileList .fileListCol {
    display: flex;
  padding: 15px;
  }
  
  .fileList .fileListBody .fileListCol {
  border-bottom: 1px solid #E7E7E7;
  }
  
  .fileList .fileListCol >  li:nth-child(1) {
  width: 40%;
  }
  
  .fileList .fileListCol >  li:nth-child(2) {
  width: 30%;
  }
  
  .fileList .fileListCol >  li:nth-child(3) {
  width: 15%;
  }
  
  .fileList .fileListCol >  li:nth-child(4) {
  width: 15%;
  }
  
 
  
  .fileList .fileListCol >  li:last-child {
  width: 6%;
  height: 50px;
  text-align: right;
  position: relative;
  }
  
  .fileList .fileListHeader > ul > li {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    /* color: #ffffff; */
  cursor: pointer;
  display: flex;
    align-items: center;
    
  }
  
  .fileList .fileListHeader ul > li.headerFilter  {
  position: relative;
  }
  
  .fileList .fileListHeader ul > li.filterActive .serviceTypeFilter {
  display: block;
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter {
    position: absolute;
    top: 100%;
    background-color: #8AC04A;
    border: 1px solid #7BAE3E;
    box-sizing: border-box;
    border-radius: 3px;
    width: 210px;
  z-index: 9;
  /* display: none; */
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    display: flex;
    padding: 10px 15px;
    align-items: center;
    justify-content: space-between;
  cursor: pointer; 
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li.selected {
    background-color: #96c65c;
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li:hover {
  opacity: .8;
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li * {
  color: rgb(0 0 0 / 20%);
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li.selected * {
  color: #FFFFFF;
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li:not(:last-child) {
  border-bottom: 1px solid #7BAE3E;
  }
  
  .fileList .fileListHeader ul > li .serviceTypeFilter li button  {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    background: rgb(255 255 255 / 20%);
    border: unset;
    box-sizing: border-box;
    border-radius: 3px;
    outline: none;
    width: 100%;
    padding: 10px;
  }
  
  .fileList ul li .seeAll {
  margin-top: 15px;
  }
  
  .fileList ul li .seeAll a {
    font-weight: 400;
    color: #B0B0B0;
    background: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  
  .fileList ul li .seeAll a:hover {
  color: #ffffff;
  background-color: #8AC04A;
  }
  
  .fileList ul li .seeAll a > * {
  margin-left: 10px;
  }
  
  .fileList .fileListHeader > ul > li > svg {
    margin-left: 4px;
  }
  
  .fileList .fileListBody .fileListCol > li {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7D7D7D;
  }
  
  .fileList .fileListCol > li:not(:last-child) {
  padding-right: 10px;
  }
  
  .fileList .fileListHeader .fileListCol > li:last-child button {
    color: #ffffff;
  background: #449AFF;
  border: 1px solid #1E73D6;
  }
  
  .fileList .fileListCol li:last-child button {
    font-size: 19px;
    color: #B0B0B0;
    background: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 3px;
    width: 50px;
    height: 50px;
    margin-left: auto;
    padding: 0;
    cursor: pointer;
    display: flex; 
    align-items: center;
    justify-content: center; 
  }
  
  .fileList .fileListHeader .fileListCol > li:last-child button:hover,
  .fileList .fileListHeader .fileListCol > li:last-child button.active {
    color: #449AFF;
  background: #ffffff;
  border: 1px solid #ffffff;
  }
  
  .fileList .fileListBody .fileListCol li:last-child button:hover,
  .fileList .fileListBody .fileListCol li:last-child button.active {
  color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;
  }
  
  .fileList .fileListCol li:last-child button.active {
  border-radius: 3px 3px 0 0;
  }
  
  .fileList .fileListCol button.active ~ ul {
  display: block;
  }
  
  .fileList .fileListHeader .fileListCol .settingMenu {
  width: 230px;
  }
  
  .fileList .fileListCol .settingMenu {
    position: absolute;
    top: 95%;
    right: 0;
    background-color: #8AC04A; 
    border: 1px solid #7BAE3E;
    box-sizing: border-box;
    border-radius: 3px 0px 3px 3px;
    width: 180px;
  /* display: none; */
  z-index: 9;
  }
  
  .fileList .fileListCol  .settingMenu li .enableFile {
  display: none;
  }
  
  .fileList .fileListCol  .settingMenu li a {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 5px 10px;
    display: flex; 
    align-items: center;
  }
  
  .fileList .fileListCol .settingMenu li:hover {
  opacity: .8;
  }
  
  .fileList .fileListCol .settingMenu li:not(:last-child) a {
  border-bottom: 1px solid #7BAE3E;
  }
  
  .fileList .fileListCol .settingMenu li a > * {
  font-size: 19px;
  margin-right: 8px;
  }
  
  .fileList .serviceName {
    display: flex;
  }
  
  .fileList .fileIcon img {
    width: 36px;
  }
  
  .fileList .fileIcon.wordType {
  background-color: #61A447;
  }
  
  .fileList .fileIcon.pdfType {
  background-color: #FBD539;
  }
  
  .fileList .fileIcon.fileType {
  background-color: #DB308F;
  }
  
  .fileList .fileIcon.xslType {
    background-color: #2E7D32;
    }
  
    .fileList .fileIcon.pptType {
      background-color: #FF5722;
    }

    .fileList .fileIcon.default {
      background-color: #00B8D4;
    }
  
    .fileList .fileIcon.pngType {
      background-color: #FF5722;
    }
  
  .fileIconContainer {
  margin-right: 10px;
  }
  
  .fileList .fileIcon {
    box-sizing: border-box;
    border-radius: 3px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fileList .fileIcon svg {
  color: #ffffff;
  }
  
  .fileList .fileContent span:last-child {
    font-weight: 300;
    line-height: 25px;
    color: #7D7D7D;
  }
  .fileList .fileContent span:first-child {
    font-weight: 400;
    line-height: 28px;
    color: #000000;
  }
  .fileList .fileContent span {
    display: block;
  }
  
  .fileList .checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  
  .fileList .fileListHeader .checkmark {
  border: 1px solid #1E73D6;
  }
  
  .fileList .checkmark {
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: inline-block;
  }
  .fileList .fileListHeader .checkbox:hover .checkmark,
  .fileList .fileListHeader .checkbox.selectedAll .checkmark {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  }
  
  .fileList .fileListHeader .checkbox .checkmark {
  background-color: #2e82e3;
  }
  
  .fileList .fileListBody .selected .checkbox .checkmark, 
  .fileList .fileListBody .fileListCol:hover .checkbox .checkmark {
    background-color: #388CEF;
  }
  
  .fileList .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  }
  
  .fileList .fileListBody > ul:hover,
  .fileList .fileListBody > ul.selected {
  background-color: rgba(56, 140, 239, 0.05);
  cursor: pointer;
  }
  
  .fileList .checkbox .checkmark:after {
  display: block;
  }
  
  .fileList .checkbox .checkmark:after {
    left: 11px;
    top: 6px;
    width: 5px;
    height: 12px;
  border: solid #E7E5E5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  }
  
  .fileList .fileListHeader .checkbox.selectedAll .checkmark:after,
  .fileList .fileListHeader .checkbox:hover .checkmark:after {
  border: solid #388cef;
  border-width: 0 3px 3px 0;
  }
  
  .fileList .fileListHeader .checkbox .checkmark:after {
    border: solid #479cff;
    border-width: 0 3px 3px 0;
  }
  
  .fileList .fileListBody .fileListCol:hover .checkmark:after, 
  .fileList .fileListBody .selected .checkmark:after {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
  }
  
  .collectionHeader {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  
  .collectionHeaderIcon {
    width: 111px;
    height: 111px;
    background-color: #8AC04A;
    border-radius: 50%;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
  
  .collectionHeader h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #272828;
  }
  
  .collectionHeader p {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: #7D7D7D;
    margin: 0;
  }
  
  
  @media screen and (min-width: 1024px) {
  
  
  }
  
  @media screen and (min-width: 1100px) {
  
  .servicesFilter .fourColumns li,
  .addServiceDocu .fourColumns li {
    line-height: 32px;
  }
  
  }
  
  @media screen and (min-width: 1150px) {
  
  
  
  }
  
  @media screen and (min-width: 1200px) {
  
  
  
  }
  
  @media screen and (min-width: 1225px) {
  
  
  }
  
  @media screen and (min-width: 1270px) {
  
  
  
  }
  
  
  @media screen and (min-width: 1440px) {	
  
  .preDefinedDocuCollections.addNewService .fourColumns {
    grid-template-columns: repeat(4,1fr);
  }
  
  }