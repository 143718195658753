.serviceDocumentContent {
  background-color: #ffffff;
}

.serviceDocumentContent > div > h2 {
  padding-bottom: 40px;
}

.btnInvalid {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.btnInvalid button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #f56666;
  border: 1px solid #f56666;
  border-radius: 6px;
  padding: 40px;
  max-width: 700px;
  width: 100%;
}

.btnExit {
  text-align: center;
  margin-top: 40px;
}

.btnExit button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f56666;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  outline: none;
  padding: 40px;
  max-width: 700px;
  width: 100%;
  cursor: pointer;
}

.btnExit button:hover {
  color: #f56666;
  background: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
}
