.main-content {
  max-width: 1440px;
  margin: 30px auto 100px auto;
  padding-right: 20px;
  padding-left: 20px;
}

.nqaDocumenTabHeader {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  margin-bottom: 10px;
}

.nqaDocumenTabHeader div {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgb(34 34 34 / 30%);
  background: #ffffff;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.nqaDocumenTabHeader > div:hover,
.nqaDocumenTabHeader .tabActive {
  color: #ffffff;
  background: #8ac04a;
}

.specificDocumentTabContent .chooseNQADocument,
.specificDocumentTabContent .chooseDocumentType,
.specificDocumentTabContent .chooseNQADocument2 {
  display: none;
}

.specificDocumentTabContent .chooseNQADocument,
.chooseNQADocument2 {
  margin-top: 10px;
}

.nqaDocumentContent {
  background-color: #ffffff;
}

.nqaDocument h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #272828;
}

.nqaDocument p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7d7d7d;
  margin: 15px auto 55px auto;
  max-width: 640px;
}

.webinarToggle {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
}

.nqaDocument > div {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 25px;
  padding-right: 30px;
  padding-left: 30px;
}

.nqaDocument > div:not(:last-child) {
  margin-bottom: 25px;
}

.nqaDocument > div > ul {
  display: grid;
}

.nqaDocument > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
}

.nqaDocument > div > ul li {
  font-size: 12px;
  line-height: 20px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.nqaDocument .serviceTypes > ul li:hover,
.nqaDocument .chooseDocumentType > ul li:hover,
.nqaDocument .serviceTypes > ul li.active,
.nqaDocument .chooseDocumentType > ul li.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.nqaDocument .chooseDocumentType > ul li.inactive {
  pointer-events: none;
  color: #b0b0b0;
}

.nqaDocument > div > ul li:hover svg,
.nqaDocument > div > ul li.active svg {
  fill: #ffffff;
}

.nqaDocument .chooseNQADocument2 ul {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
}

.nqaDocument .chooseNQADocument2 ul li:nth-child(1):hover,
.nqaDocument .chooseNQADocument2 ul li:nth-child(1).active {
  color: #ffffff;
  background-color: #fbd539;
  border: 1px solid #fbd539;
}

.nqaDocument .chooseNQADocument2 ul li:nth-child(2):hover,
.nqaDocument .chooseNQADocument2 ul li:nth-child(2).active {
  color: #ffffff;
  background-color: #e395a5;
  border: 1px solid #e395a5;
}

.nqaDocument .chooseNQADocument ul {
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 4px;
}

.nqaDocument .chooseNQADocument ul li:nth-child(1):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(1).active {
  color: #ffffff;
  background-color: #61a447;
  border: 1px solid #61a447;
}

.nqaDocument .chooseNQADocument ul li:nth-child(2):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(2).active {
  color: #ffffff;
  background-color: #fbd539;
  border: 1px solid #fbd539;
}

.nqaDocument .chooseNQADocument ul li:nth-child(3):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(3).active {
  color: #ffffff;
  background-color: #e395a5;
  border: 1px solid #e395a5;
}

.nqaDocument .chooseNQADocument ul li:nth-child(4):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(4).active {
  color: #ffffff;
  background-color: #accf6b;
  border: 1px solid #accf6b;
}

.nqaDocument .chooseNQADocument ul li:nth-child(5):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(5).active {
  color: #ffffff;
  background-color: #f3a23e;
  border: 1px solid #f3a23e;
}

.nqaDocument .chooseNQADocument ul li:nth-child(6):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(6).active {
  color: #ffffff;
  background-color: #db308f;
  border: 1px solid #db308f;
}

.nqaDocument .chooseNQADocument ul li:nth-child(7):hover,
.nqaDocument .chooseNQADocument ul li:nth-child(7).active {
  color: #ffffff;
  background-color: #3a96cf;
  border: 1px solid #3a96cf;
}

.nqaDocument .chooseDocumentType ul {
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  max-width: 1080px;
}

.nqaDocument .chooseDocumentType ul li svg {
  margin-right: 10px;
}

.nqaDocument .serviceTypes ul li img {
  margin-right: 15px;
}

.nqaDocument .serviceTypes ul {
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
}

.nqaDocument .serviceTypes ul li {
  height: 80px;
  padding: 20px 10px;
  justify-content: flex-start;
}

.nqaDocument .serviceTypes ul li span {
  display: flex;
}

.nqaDocument .serviceTypes ul li:hover::before,
.nqaDocument .serviceTypes ul li.active::before {
  content: " ";
  display: block;
  border: solid 0.8em #ffffff;
  border-radius: 0.8em;
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  top: 4px;
}

.nqaDocument .serviceTypes ul li:hover::after,
.nqaDocument .serviceTypes ul li.active::after {
  content: " ";
  display: block;
  width: 0.3em;
  height: 0.6em;
  border: solid #8ac04a;
  border-width: 0 0.2em 0.2em 0;
  position: absolute;
  right: 14px;
  top: 12px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nqaDocument .serviceTypes ul li svg {
  fill: #b0b0b0;
  width: 35px;
  margin-right: 10px;
}

.nqaDocument .serviceTypes ul li:hover svg,
.nqaDocument .serviceTypes ul li.active svg {
  fill: #ffffff;
}

.specificDocumentFilter {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  padding-right: 30px;
  padding-left: 30px;
}

.specificDocumentFilter li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.specificDocumentFilter li:hover,
.specificDocumentFilter .active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.nqaDocument {
  padding-top: 60px;
}

.generateFileTabContent {
  display: none;
}

.inputContainer h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
}

.inputContainer .inputRow {
  display: grid;
  grid-column-gap: 25px;
}

.inputContainer .webinarInputRow {
  display: grid;
  grid-column-gap: 25px;
}

.addServiceDocu {
  padding: 0 0 60px;
}

.addServiceDocu .inputContainer .inputRow {
  grid-template-columns: repeat(3, 1fr);
}

.addServiceDocu .inputContainer .webinarInputRow {
  grid-template-columns: repeat(2, 1fr);
}

.inputContainer > div:not(:last-child) {
  margin-bottom: 20px;
}

.inputContainer .inputGroup > * {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  display: block;
}

.inputContainer .inputGroup text {
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(248, 113, 113);
}

.inputContainer .inputGroup input {
  height: 60px;
}

.inputContainer .inputGroup select,
.inputContainer .inputGroup input,
.inputContainer .inputGroup textarea {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 20px;
  width: -webkit-fill-available;
  outline: none;
  width: 100%;
}

::placeholder {
  color: #b7b8b7;
}

.addServiceDocu .inputContainer .inputFull .inputGroup .inputContent {
  height: 400px;
}

.addServiceDocu .inputContainer .inputFull .inputGroup .inputDesc {
  height: 150px;
}

.inputContainer .inputActionButton {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 455px;
}

.inputContainer .inputActionButton .chooseServicesBtn {
  margin-right: 5px;
}

.inputNotify {
  max-width: 540px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.inputNotify h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  margin-top: 25px;
}

.inputNotify p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 60px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f4f3f3;
  border: 1px solid #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider:before {
  left: 33px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #8bc334;
  border: 1px solid #75ac20;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8bc334;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.fileOrigin {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 370px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  margin-top: 70px;
  margin-bottom: 70px !important;
}

.fileOrigin .radioBtn {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fileOrigin input {
  display: none;
}

.fileOrigin .checkmark {
  position: relative;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 15px;
}

.fileOrigin input ~ .checkmark,
.fileOrigin input:checked ~ .checkmark {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.fileOrigin input ~ .checkmark:after,
.fileOrigin input:checked ~ .checkmark:after {
  display: block;
}

.fileOrigin input ~ .checkmark:after,
.fileOrigin input:checked ~ .checkmark:after {
  top: 6px;
  left: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8ac04a;
  border: 1px solid #7bae3e;
}

.fileOrigin input ~ .checkmark:after {
  background: #ffffff;
  border: 1px solid #ebebeb;
}

.uploadFile {
  background: #f9f9f9;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 90px;
  text-align: center;
  margin-bottom: 40px !important;
}

.uploadFile span {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #7d7d7d;
  opacity: 0.4;
  display: block;
  margin-bottom: 18px;
}

/* .uploadFile label { */
/* font-size: 16px; */
/* line-height: 19px; */
/* text-transform: uppercase; */
/* color: #F9F9F9; */
/* background: #388CEF; */
/* border: 1px solid #388CEF; */
/* box-sizing: border-box; */
/* border-radius: 3px; */
/* display: inline-block; */
/* padding: 15px; */
/* cursor: pointer; */
/* } */

/* .fileOrigin, */
/* .uploadFile, */
.uploadFile label input {
  display: none;
}

.uploadFile,
.inputRow,
.webinarInputRow,
.inputFull {
  margin-right: 30px;
  margin-left: 30px;
}

.uploadedFile.fileOriginGenerate {
  margin-top: 50px !important;
  margin-bottom: 0 !important;
  /* border-top: 1px solid #e7e7e7; */
  border-bottom: 1px solid #e7e7e7;
}

.uploadedFile.fileOriginGenerate ul {
  /* margin-top: 35px; */
  margin-bottom: 50px;
  /* display: none; */
}

.nqaCollections {
  display: none;
}

.fileOriginUpload {
  margin-top: 60px !important;
  margin-bottom: 70px !important;
}

.uploadedFile {
  padding-right: 30px;
  padding-left: 30px;
  /* display: none; */
}

.uploadedFile > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  text-align: center;
  margin-bottom: 60px;
}

.uploadedFile ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ebebeb;
}

.uploadedFile ul li:not(:last-child) {
  margin-bottom: 10px;
}

.blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  line-height: 19px;
  text-transform: uppercase;
  outline: none;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.blueButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.main-content button svg {
  margin-left: 5px;
}

.download {
  margin-right: 5px;
}

.removeFile {
  color: #f56666;
  background-color: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  /* display: none; */
}

.removeFile:hover {
  color: #ffffff;
  background-color: #f56666;
}

.uploadedFile .uploadedFileContent {
  display: flex;
  align-items: center;
}

.uploadedFile .fileIcon {
  color: #ffffff;
  background: #61a447;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.uploadedFile .uploadedFileContent .fileInfo span {
  font-size: 16px;
  display: block;
}

.uploadedFile .uploadedFileContent .fileInfo .fileName {
  font-weight: 400;
  line-height: 28px;
}

.uploadedFile .uploadedFileContent .fileInfo .fileSize {
  font-weight: 300;
  line-height: 25px;
}

.addFiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px 0;
}

.addFiles span {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
}

.chooseServices {
  margin-bottom: 0 !important;
  padding: 30px 30px 50px;
  border-bottom: 1px solid #ebebeb;
  text-align: center;
}

.chooseServices p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  margin: 0;
}

/**/
.selectedServices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.excludeServices .selectedServices li {
  color: #f56666;
  background-color: #fde0e0;
}

.excludeServices .selectedServices li:hover {
  color: #ffffff;
  background-color: #f56666;
}

.selectedServices li {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #8f5fe8;
  background-color: #e9dffa;
  padding: 10px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selectedServices li:hover {
  color: #ffffff;
  background-color: #8f5fe8;
}

.selectedServices li svg {
  margin-left: 8px;
}

/*
*exlude selected
*/

.excludeSelectedServices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.excludeServices .excludeSelectedServices li {
  color: #f56666;
  background-color: #fde0e0;
}

.excludeServices .excludeSelectedServices li:hover {
  color: #ffffff;
  background-color: #f56666;
}

.excludeSelectedServices li {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #f56666;
  background-color: #fde0e0;
  padding: 10px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.excludeSelectedServices li:hover {
  color: #ffffff;
  background-color: #f56666;
}

.excludeSelectedServices li svg {
  margin-left: 8px;
}

/**/

.btnSave {
  text-align: center;
  margin-top: 40px;
}

.contentEditor {
  height: 500px;
  border: 1px solid #000000;
  border-radius: 3px;
}

.btnSave button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #388cef;
  border: 1px solid #388cef;
  border-radius: 6px;
  outline: none;
  padding: 40px;
  max-width: 700px;
  width: 100%;
  cursor: pointer;
}

.titleContent h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #272828;
}

.focusMonth > div {
  border-bottom: 1px solid #ebebeb;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.optionsList > div > ul {
  display: grid;
}

.optionsList > div > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
}

.optionsList > div > ul li {
  font-size: 16px;
  line-height: 25px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.optionsList .serviceTypes > ul li:hover,
.optionsList .chooseDocumentType > ul li:hover,
.optionsList .serviceTypes > ul li.active,
.optionsList .chooseDocumentType > ul li.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.optionsList .chooseNQADocument ul {
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 4px;
}

.optionsList .chooseNQADocument ul li:nth-child(1):hover,
.optionsList .chooseNQADocument ul li:nth-child(1).active {
  color: #ffffff;
  background-color: #61a447;
  border: 1px solid #61a447;
}

.optionsList .chooseNQADocument ul li:nth-child(2):hover,
.optionsList .chooseNQADocument ul li:nth-child(2).active {
  color: #ffffff;
  background-color: #fbd539;
  border: 1px solid #fbd539;
}

.optionsList .chooseNQADocument ul li:nth-child(3):hover,
.optionsList .chooseNQADocument ul li:nth-child(3).active {
  color: #ffffff;
  background-color: #e395a5;
  border: 1px solid #e395a5;
}

.optionsList .chooseNQADocument ul li:nth-child(4):hover,
.optionsList .chooseNQADocument ul li:nth-child(4).active {
  color: #ffffff;
  background-color: #accf6b;
  border: 1px solid #accf6b;
}

.optionsList .chooseNQADocument ul li:nth-child(5):hover,
.optionsList .chooseNQADocument ul li:nth-child(5).active {
  color: #ffffff;
  background-color: #f3a23e;
  border: 1px solid #f3a23e;
}

.optionsList .chooseNQADocument ul li:nth-child(6):hover,
.optionsList .chooseNQADocument ul li:nth-child(6).active {
  color: #ffffff;
  background-color: #db308f;
  border: 1px solid #db308f;
}

.optionsList .chooseNQADocument ul li:nth-child(7):hover,
.optionsList .chooseNQADocument ul li:nth-child(7).active {
  color: #ffffff;
  background-color: #3a96cf;
  border: 1px solid #3a96cf;
}

.optionsList .chooseDocumentType ul {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4px;
  max-width: 765px;
}

.optionsList .chooseDocumentType ul li svg {
  margin-right: 10px;
}

.YearList ul {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
  max-width: 214px;
  margin: 40px auto 0 auto;
}

.YearList ul li.active,
.MonthList ul li.active,
.YearList ul li:hover,
.MonthList ul li:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.MonthList ul {
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 4px;
}

.fileList {
  padding-top: 65px;
  margin-top: 70px;
  margin-bottom: 50px;
  border-top: 1px solid #ebebeb;
}

.fileList h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 25px;
}

.fileList .fileListHeader .fileListCol {
  background: #388cef;
  align-items: center;
}

.fileList .fileListHeader .fileListCol {
  padding: 35px 30px;
}

.fileList .fileListBody .fileListCol {
  padding: 25px 30px;
}

.fileList .fileListCol {
  display: flex;
}

.fileList .fileListBody .fileListCol {
  border-bottom: 1px solid #e7e7e7;
}

.fileList .fileListCol > li:nth-child(1) {
  width: 30%;
}

.fileList .fileListCol > li:nth-child(2) {
  width: 20%;
}

.fileList .fileListCol > li:nth-child(3) {
  width: 15%;
}

.fileList .fileListCol > li:nth-child(4) {
  width: 20%;
}

.fileList .fileListCol > li:nth-child(5) {
  width: 15%;
}

.fileList .fileListHeader > ul > li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.fileList .fileListBody .fileListCol > li {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #7d7d7d;
}

.fileList .fileListCol > li:not(:last-child) {
  padding-right: 10px;
}

.fileList .serviceName {
  display: flex;
}

.fileIconContainer {
  margin-right: 10px;
}

.fileList .fileIcon {
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbd539;
}

.fileList .fileIcon svg {
  color: #ffffff;
}

.fileList .fileContent span:first-child {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
  overflow-wrap: anywhere;
}
.fileList .fileContent span {
  display: block;
}

.fileListBody .fileListCol .btnDownload button {
  color: #8ac04a;
  background: #f3ffe4;
  border: 1px solid #c6e79f;
}

.fileListBody .fileListCol .btnDownload button:hover {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.fileListBody .fileListCol .btnDelete button {
  color: #f56666;
  background: #ffe9e9;
  border: 1px solid rgba(245, 102, 102, 0.4);
}

.fileListBody .fileListCol .btnDelete button:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
}

.fileListBody .fileListCol .btnAction button {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fileListBody .fileListCol .btnAction button > * {
  margin-right: 8px;
  font-size: 12px;
}

.fileListBody .fileListCol .audienceRow {
  display: flex;
  align-items: center;
  position: relative;
}

.fileListBody .fileListCol .audienceRow button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8ac04a;
  background: #f3ffe4;
  border: 1px solid #c6e79f;
  box-sizing: border-box;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.audienceRow button.unClicked {
  color: #8ac04a !important;
  background: #f3ffe4 !important;
  border-radius: 3px !important;
  border: 1px solid #c6e79f !important;
}

.audienceRow button.clicked {
  color: #ffffff !important;
  background: #8ac04a !important;
  border-radius: 3px 3px 0px 0px !important;
  border: none !important;
}

.fileListBody .fileListCol .audienceRow button.active {
  background: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 3px 3px 0px 0px;
}

.fileListBody .fileListCol .audienceRow button.active > * {
  color: #ffffff;
}

.fileListBody .fileListCol .audienceRow .audienceList {
  position: absolute;
  top: 100%;
  left: 0;
  background: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 0px 3px 3px 3px;
  z-index: 9;
  display: none;
}

.fileListBody .fileListCol .audienceRow .active ~ .audienceList {
  display: block;
}

.fileListBody .fileListCol .audienceRow .audienceList li > * {
  margin-right: 5px;
}

.fileListBody .fileListCol .audienceRow .audienceList li:not(:last-child) {
  border-bottom: 1px solid #7bae3e;
}

.fileListBody .fileListCol .audienceRow .audienceList li.selected {
  color: rgb(255 255 255 / 60%);
}

.fileListBody .fileListCol .audienceRow .audienceList li {
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 15px 10px 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.inputNotifyContainer {
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.btnSave button:hover {
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
}

.tableMenuAction {
  position: absolute;
  top: 98%;
  left: 0px;
  background: #8ac04a;
  border-radius: 0px 3px 3px 3px;
  width: 216px;
  z-index: 9;
  /* display: none; */
}

.tableMenuAction li {
  font-size: 16px;
  line-height: 19px;
  padding: 12px 10px;
  color: #ffffff;
  cursor: pointer;
}

.tableMenuAction li svg {
  color: #ffffff;
}

.tableMenuAction li:not(:last-child) {
  border-bottom: 1px solid #7bae3e;
}

.tableMenuAction {
  display: block;
}

.cogAudience {
  font-weight: 900;
  font-size: 14px;
  line-height: 32px;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: #272828;
}

.subHeaderText {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  color: #7d7d7d;
}

@media screen and (min-width: 1024px) {
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: flex;
  }

  .mobileMenu {
    display: none;
  }

  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 12px;
  }
  .nqaDocumenTabHeader div {
    font-size: 20px;
  }
  .nqaDocument > div > ul li {
    font-size: 16px;
    line-height: 25px;
  }
  .nqaDocument .serviceTypes ul li {
    height: 90px;
    padding: 20px 22px;
  }
  .nqaDocument .serviceTypes ul li svg {
    width: 45px;
    margin-right: 15px;
  }
  .fileListBody .fileListCol .btnAction button {
    font-size: 16px;
    line-height: 20px;
  }

  .fileListBody .fileListCol .btnAction button > * {
    font-size: 16px;
  }
  .fileList .fileListHeader > ul > li {
    font-size: 20px;
    line-height: 28px;
  }

  .fileList .fileListBody .fileListCol > li {
    font-size: 16px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1100px) {
  .nqaDocument > div > ul li {
    line-height: 32px;
  }
}

@media screen and (min-width: 1150px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 15px;
  }
}

@media screen and (min-width: 1225px) {
  .products .product {
    width: 15.8%;
  }

  .products .product:not(:nth-last-child(-n + 3)) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1270px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 17px;
  }
}

@media screen and (min-width: 1440px) {
}
