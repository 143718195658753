.maincontent {
  max-width: 1440px;
  margin: 30px auto 20px auto;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}

.uploadNewsLetter h2,
.uploadNewsLetter .inputRow,
.uploadNewsLetter .uploadFile {
  margin-right: 30px;
  margin-left: 30px;
}

.uploadNewsLetter {
  background-color: #ffffff;
  padding-top: 60px;
  padding-bottom: 60px;
}

.uploadNewsLetter h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #272828;
  position: relative;
}

.inputNotify p,
.uploadNewsLetter p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7d7d7d;
}

.inputNotify p {
  margin: 5px auto auto;
  max-width: 420px;
}

.uploadNewsLetter > p {
  margin: 15px auto 70px auto;
  max-width: 640px;
}

.inputContainer {
  margin-top: 30px;
}

.inputContainer .inputRow {
  display: grid;
  grid-column-gap: 25px;
}

.inputContainer .inputRow {
  grid-template-columns: repeat(3, 1fr);
}

.selectWrap {
  position: relative;
  height: 60px;
}

.selectWrap::after {
  content: "▼";
  font-size: 1rem;
  top: 70%;
  right: 15px;
  position: absolute;
}

.selectWrap select {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 18px 20px;
  padding-right: 30px;
  background: #ffffff;
  font-size: 20px;
  -webkit-appearance: none;
  appearance: none;
  color: #b7b8b7;
}

.thumbnail h3 {
  font-size: 20px;
  text-align: center;
}

.inputContainer .inputGroup > * {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  display: block;
}

.inputContainer .inputGroup input {
  height: 60px;
}

.inputContainer .inputGroup input {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 20px;
  width: -webkit-fill-available;
  outline: none;
}

::placeholder {
  color: #b7b8b7;
}

.inputContainer .inputActionButton {
  text-align: center;
  margin-top: 25px;
}

.blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.blueButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.inputNotify {
  margin-top: 70px;
  padding-top: 40px;
  text-align: center;
  border-top: 1px solid #ebebeb;
}

.inputNotify h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  margin-top: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 115px;
  height: 60px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f3f3;
  border: 1px solid #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider:before {
  left: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  left: 4px;
  bottom: 3px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #8bc334;
  border: 1px solid #8bc334;
}

input:focus + .slider {
  box-shadow: 0 0 1px #8bc334;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.uploadFile {
  background: #f9f9f9;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 90px;
  text-align: center;
  margin-bottom: 40px !important;
}

.uploadFile span {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #7d7d7d;
  opacity: 0.4;
  display: block;
  margin-bottom: 18px;
}

.uploadFile label input {
  display: none;
}

.btnSave {
  text-align: center;
  margin-top: 40px;
}

.btnSave button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f9f9f9;
  background: #388cef;
  border: 1px solid #388cef;
  border-radius: 6px;
  outline: none;
  padding: 40px;
  max-width: 700px;
  width: 100%;
  cursor: pointer;
}

.btnSave button:hover {
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
}

.fileList {
  margin-top: 150px;
}

.fileList h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 25px;
}

.fileList .fileListHeader .fileListCol {
  background: #388cef;
  align-items: center;
}

.fileList .fileListHeader .fileListCol {
  padding: 35px 30px;
}

.fileList .fileListBody .fileListCol {
  padding: 25px 30px;
}

.fileList .fileListCol {
  display: flex;
}

.fileList .fileListBody .fileListCol {
  border-bottom: 1px solid #e7e7e7;
}

.fileList .fileListCol > li:nth-child(1) {
  width: 47%;
}

.fileList .fileListCol > li:nth-child(2) {
  width: 18%;
}

.fileList .fileListCol > li:nth-child(3) {
  width: 20%;
}

.fileList .fileListCol > li:nth-child(4) {
  width: 15%;
}

.fileList .fileListHeader > ul > li {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.fileList .fileListBody .fileListCol > li {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
}

.fileList .fileListCol > li:not(:last-child) {
  padding-right: 10px;
}

.fileList .serviceName {
  display: flex;
}

.fileIconContainer {
  margin-right: 10px;
}

.fileList .fileIcon {
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbd539;
}

.fileList .fileIcon svg {
  color: #ffffff;
}

.fileList .fileContent span:first-child {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}
.fileList .fileContent span {
  display: block;
}

.fileListBody {
  background-color: #ffffff;
}

.fileListBody .fileListCol .btnDownload button {
  color: #8ac04a;
  background: #f3ffe4;
  border: 1px solid #c6e79f;
}

.fileListBody .fileListCol .btnDownload button:hover {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.fileListBody .fileListCol .btnDelete button {
  color: #f56666;
  background: #ffe9e9;
  border: 1px solid rgba(245, 102, 102, 0.4);
}

.fileListBody .fileListCol .btnDelete button:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
}

.fileListBody .fileListCol .btnAction button {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fileListBody .fileListCol .btnAction button > * {
  margin-right: 8px;
  font-size: 16px;
}

.fileListBody .fileListCol .audienceRow {
  display: flex;
  align-items: center;
  position: relative;
}

.fileListBody .fileListCol .audienceRow button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8ac04a;
  background: #f3ffe4;
  border: 1px solid #c6e79f;
  box-sizing: border-box;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.fileListBody .fileListCol .audienceRow button.active {
  background: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 3px 3px 0px 0px;
}

.fileListBody .fileListCol .audienceRow button.active > * {
  color: #ffffff;
}

.fileListBody .fileListCol .audienceRow .audienceList {
  position: absolute;
  top: 100%;
  left: 0;
  background: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 0px 3px 3px 3px;
  z-index: 9;
  display: none;
}

.fileListBody .fileListCol .audienceRow .active ~ .audienceList {
  display: block;
}

.fileListBody .fileListCol .audienceRow .audienceList li > * {
  margin-right: 5px;
}

.fileListBody .fileListCol .audienceRow .audienceList li:not(:last-child) {
  border-bottom: 1px solid #7bae3e;
}

.fileListBody .fileListCol .audienceRow .audienceList li.selected {
  color: rgb(255 255 255 / 60%);
}

.fileListBody .fileListCol .audienceRow .audienceList li {
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  padding: 10px 15px 10px 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pagination {
  padding: 50px 30px;
  background-color: #ffffff;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}
.pagination a {
  font-size: 16px;
  width: 68px;
  height: 60px;
  color: #222222;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.uploadedFile .uploadedFileContent {
  display: flex;
  align-items: center;
}

.uploadedFile .fileIcon {
  color: #ffffff;
  background: #61a447;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.uploadedFile .uploadedFileContent .fileInfo span {
  font-size: 16px;
  display: block;
}

.uploadedFile .uploadedFileContent .fileInfo .fileName {
  font-weight: 400;
  line-height: 28px;
}

.uploadedFile .uploadedFileContent .fileInfo .fileSize {
  font-weight: 300;
  line-height: 25px;
}

.uploadedFile {
  padding-right: 30px;
  padding-left: 30px;
  /* display: none; */
}

.uploadedFile > span {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  text-align: center;
  margin-bottom: 60px;
}

.download {
  margin-right: 5px;
}

.removeFile {
  color: #f56666;
  background-color: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  line-height: 16px;
  /* display: none; */
}

.removeFile > * {
  margin-left: 8px;
}

.removeFile:hover {
  color: #ffffff;
  background-color: #f56666;
}

.uploadedFile ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ebebeb;
}

.fileOriginUpload {
  margin-top: 60px !important;
  margin-bottom: 70px !important;
}

.uploadedFile ul li:not(:last-child) {
  margin-bottom: 10px;
}

.uploadedFile.fileOriginGenerate {
  margin-top: 50px !important;
  margin-bottom: 0 !important;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.uploadedFile.fileOriginGenerate ul {
  /* margin-top: 35px; */
  margin-bottom: 50px;
  /* display: none; */
}

@media screen and (min-width: 1100px) {
  .fileListBody .fileListCol .btnAction button {
    font-size: 20px;
    line-height: 24px;
    padding: 15px;
  }

  .fileListBody .fileListCol .btnAction button > * {
    font-size: 19px;
  }
}

@media screen and (min-width: 1400px) {
  .fileList .fileListCol > li:nth-child(1) {
    width: 50%;
  }

  .fileList .fileListCol > li:nth-child(2) {
    width: 25%;
  }

  .fileList .fileListCol > li:nth-child(3) {
    width: 15%;
  }

  .fileList .fileListCol > li:nth-child(4) {
    width: 10%;
  }
}

.cogContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #8ac04a;
  border-radius: 3px;
  border-top-right-radius: 0px;
}

.cogContainer > button {
  display: flex;
  padding: 15px;
  font-size: 16px;
  color: white;
  padding-right: 25px;
}

.cogContainer > button:not(:last-child) {
  @apply border-b border-hubGray border-opacity-50;
  border-bottom: 1px solid #7bae3ea1;
}

.cogContainer > button > svg {
  margin-right: 10px;
}

.cogNormal {
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
}

.cogActive {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #7bae3ea1;
}

.btnExit {
  text-align: center;
  margin-top: 40px;
}

.btnExit button {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f56666;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  outline: none;
  padding: 40px;
  max-width: 700px;
  width: 100%;
  cursor: pointer;
}

.btnExit button:hover {
  color: #f56666;
  background: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
}

.noCategory,
.noCategory option:disabled {
  color: #b7b8b7 !important;
}

.noCategory option {
  color: black !important;
}

p.photoName {
  font-size: 20px;
  font-weight: 400;
  color: #272828;
}

p.photoSize {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 300;
}
