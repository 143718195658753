.modalContainer {
  display: flex;
  padding: 2rem 4rem;
  align-items: center;
  flex-direction: column;
}

.headerText {
  margin: 1rem 0 2rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}

.headerError {
  color: red;
}
