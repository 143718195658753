.main-content {
  max-width: 1440px;
  margin: 30px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;
}

.serviceType{
  padding: 45px 35px 35px;
}

.serviceType ul{
  display: grid;
  grid-template-columns: repeat(8,1fr);
}

.serviceType li{
  width: 160px;
  height: 41px;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  color: #B0B0B0;
}

.serviceType li:hover{
  cursor: pointer;
}

.serviceType li.active{
  background-color: #61A447;
  color: #FFFFFF;
}

.search {
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  width: 330px;
}


.search input {
font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: #222222;
  padding: 10px 15px;
  border: unset;
outline: none;
}

::placeholder {
color: #B0B0B0;
}

.search button {
  height: 33px;
  width: 33px;
color: #ffffff;
background: #8AC04A;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
outline: none;
}

.search button:hover {
opacity: .8;
}

.tabHeader {
  background-color: #ffffff;
}

.tabHeader ul {
  display: grid;
  grid-template-columns: repeat(7,1fr);
}

.tabHeader ul li:not(:last-child) {
  border-right: 4px solid #f2f2f2;
}

.tabHeader ul li:hover,
.tabHeader ul li.active {
font-weight: 400;
color: #222222;
border-bottom: 4px solid #ffffff;
}

.tabHeader ul li:hover::before,
.tabHeader ul li.active::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-370%, -50%);
}

.tabHeader ul li:nth-child(1):hover::before,
.tabHeader ul li:nth-child(1).active::before {
 background-color: #61a447;
}

.tabHeader ul li:nth-child(2):hover::before,
.tabHeader ul li:nth-child(2).active::before {
 background-color: #fbd539;
}

.tabHeader ul li:nth-child(3):hover::before,
.tabHeader ul li:nth-child(3).active::before {
 background-color: #e395a5;
}

.tabHeader ul li:nth-child(4):hover::before,
.tabHeader ul li:nth-child(4).active::before {
 background-color: #accf6b;
}

.tabHeader ul li:nth-child(5):hover::before,
.tabHeader ul li:nth-child(5).active::before {
 background-color: #f3a23e;
}

.tabHeader ul li:nth-child(6):hover::before,
.tabHeader ul li:nth-child(6).active::before {
 background-color: #db308f;
}

.tabHeader ul li:nth-child(7):hover::before,
.tabHeader ul li:nth-child(7).active::before {
 background-color: #3a96cf;
}

.tabHeader ul li {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid #f2f2f2;
font-weight: 300;
font-size: 16px;
color: rgb(34 34 34 / 40%);
cursor: pointer;
position: relative;
}

.pagination {
padding: 30px 30px 0 30px;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}

.pagination a {
font-size: 16px;
  width: 68px;
  height: 60px;
color: #222222;
  background-color: #F9F9F9;
border: 1px solid #EBEBEB;
box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #8AC04A;
}

.documentsList {
  background-color: #ffffff;
  padding-bottom: 30px;
}

.filterContainer {
  padding: 45px 35px 35px;
}


.chooseDocumentType .label {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  display: block;
  margin-bottom: 15px;
}

.filterContainer .label {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  display: block;	
}

.filterContainer .actions button {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #B0B0B0;
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 15px;
cursor: pointer;
}

.filterContainer .actions button:hover,
.filterContainer .actions button.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #7BAE3E;
}

.actions button:not(:last-child) {
  margin-right: 5px;
}

.actions button i { 
font-size: 15px;
margin-right: 8px;
}

.filterByServices,
.filterByType,
.servicesFilter {
margin-top: 40px;
display: none;
}

.servicesFilter ul {
display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 10px;
}

.servicesFilter ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #B0B0B0;
  background-color: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.servicesFilter ul li svg {
fill: #B0B0B0;
width: 45px;
}

.servicesFilter ul li:hover,
.servicesFilter ul .active {
color: #ffffff;
  background-color: #8AC04A;
  border: 1px solid #7BAE3E;
}

.servicesFilter ul li:hover svg,
.servicesFilter ul .active svg {
fill: #ffffff;
}

.servicesFilter ul li span {
  margin-right: 15px;
  display: flex;
}

.servicesFilter .label {
  color: #000000;
}

.fileList .fileListHeader .fileListCol {
background: #388CEF;
align-items: center;
}

.fileList .fileListCol {
  display: flex;
padding: 20px;
}

.fileList .fileListBody .fileListCol {
border-bottom: 1px solid #E7E7E7;
}

.fileList .fileListCol >  li:nth-child(1) {
width: 4%;
}

.fileList .fileListCol >  li:nth-child(2) {
width: 23%;
}

.fileList .fileListCol >  li:nth-child(3) {
width: 11%;
}

.fileList .fileListCol >  li:nth-child(4) {
width: 13%;
}

.fileList .fileListCol >  li:nth-child(5) {
width: 13%;
}

.fileList .fileListCol >  li:nth-child(6) {
width: 18%;
}  

.fileList .fileListCol >  li:nth-child(7) {
width: 12%;
}  

.fileList .fileListCol >  li:nth-child(7) p {
margin: 0;
}  

.fileList .fileListCol >  li:nth-child(7) .disabledCollection {
color: #F56666;
display: flex;
}

.fileList .fileListCol >  li:nth-child(7) p svg {
margin-right: 5px;
}  

.fileList .fileListCol >  li:last-child {
width: 6%;
height: 50px;
text-align: right;
position: relative;
}

.fileList .fileListHeader > ul > li {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
cursor: pointer;
display: flex;
  align-items: center;
}

.fileList ul li .seeAll {
margin-top: 15px;
}

.fileList ul li .seeAll a {
  font-weight: 400;
  color: #B0B0B0;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.fileList ul li .seeAll a:hover {
color: #ffffff;
background-color: #8AC04A;
}

.fileList ul li .seeAll a > * {
margin-left: 10px;
}

.fileList .fileListHeader > ul > li > svg {
  margin-left: 4px;
}

.fileList .fileListBody .fileListCol > li {
font-weight: 300;
font-size: 16px;
line-height: 25px;
color: #7D7D7D;
}

.fileList .fileListCol > li:not(:last-child) {
padding-right: 10px;
}

.fileList .fileListHeader .fileListCol li:last-child button {
  color: #ffffff;
background: #449AFF;
border: 1px solid #1E73D6;
}

.fileList .fileListCol li:last-child button {
  font-size: 19px;
  color: #B0B0B0;
  background: #F9F9F9;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  padding: 0;
  cursor: pointer;
  display: flex; 
  align-items: center;
  justify-content: center; 
}

.fileList .fileListHeader .fileListCol li:last-child button:hover,
.fileList .fileListHeader .fileListCol li:last-child button.active {
  color: #449AFF;
background: #ffffff;
border: 1px solid #ffffff;
}

.fileList .fileListBody .fileListCol li:last-child button:hover,
.fileList .fileListBody .fileListCol li:last-child button.active {
color: #ffffff;
background-color: #8AC04A;
border: 1px solid #7BAE3E;
}

.fileList .fileListCol li:last-child button.active {
border-radius: 3px 3px 0 0;
}

.fileList .fileListCol button.active ~ ul {
display: block;
}

.fileList .fileListHeader .fileListCol .settingMenu {
width: 230px;
}

.fileList .fileListCol .settingMenu {
  position: absolute;
  top: 95%;
  right: 0;
  background-color: #8AC04A; 
  border: 1px solid #7BAE3E;
  box-sizing: border-box;
  border-radius: 3px 0px 3px 3px;
  width: 250px;
/* display: none; */
z-index: 9;
}

.fileList .fileListCol  .settingMenu li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #FFFFFF;
  padding: 5px 10px;
  display: flex; 
  align-items: center;
}

.fileList .fileListCol .settingMenu li:hover {
opacity: .8;
}

.fileList .fileListCol .settingMenu li:not(:last-child) a {
border-bottom: 1px solid #7BAE3E;
}

.fileList .fileListCol .settingMenu li a > * {
font-size: 19px;
margin-right: 8px;
}

.fileList .serviceName {
  display: flex;
}

.fileIcon img {
  width: 36px;
}

.fileIcon.wordType {
background-color: #61A447;
}

.fileIcon.pdfType {
background-color: #FBD539;
}

.fileIcon.fileType {
background-color: #DB308F;
}

.fileIcon.xslType {
  background-color: #2E7D32;
  }

  .fileIcon.pptType {
    background-color: #FF5722;
  }

  .fileIcon.default {
    background-color:  
    #E65100
  }

  .fileIcon.image {
    background-color: #00B8D4;
  }

  .fileIcon.pngType {
    background-color: #FF5722;
  }

.fileIconContainer {
margin-right: 10px;
}

.fileIcon {
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileIcon svg {
color: #ffffff;
}

.fileList .fileContent span:first-child {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}
.fileList .fileContent span {
  display: block;
}

.fileList .checkbox {
display: inline-block;
position: relative;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.fileList .fileListHeader .checkmark {
border: 1px solid #1E73D6;
}

.fileList .checkmark {
border: 1px solid #EBEBEB;
box-sizing: border-box;
border-radius: 3px;
width: 30px;
height: 30px;
display: inline-block;
}
.fileList .fileListHeader .checkbox:hover .checkmark,
.fileList .fileListHeader .checkbox.selectedAll .checkmark {
background-color: #ffffff;
border: 1px solid #ffffff;
}

.fileList .fileListHeader .checkbox .checkmark {
background-color: #2e82e3;
}

.fileList .fileListBody .selected .checkbox .checkmark, 
.fileList .fileListBody .fileListCol:hover .checkbox .checkmark {
  background-color: #388CEF;
}

.fileList .checkmark:after {
content: "";
position: absolute;
display: none;
}

.fileList .fileListBody > ul:hover,
.fileList .fileListBody > ul.selected {
background-color: rgba(56, 140, 239, 0.05);
cursor: pointer;
}

.fileList .checkbox .checkmark:after {
display: block;
}

.fileList .checkbox .checkmark:after {
  left: 11px;
  top: 6px;
  width: 5px;
  height: 12px;
border: solid #E7E5E5;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.fileList .fileListHeader .checkbox.selectedAll .checkmark:after,
.fileList .fileListHeader .checkbox:hover .checkmark:after {
border: solid #388cef;
border-width: 0 3px 3px 0;
}

.fileList .fileListHeader .checkbox .checkmark:after {
  border: solid #479cff;
  border-width: 0 3px 3px 0;
}

.fileList .fileListBody .fileListCol:hover .checkmark:after, 
.fileList .fileListBody .selected .checkmark:after {
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
}

.filterByServices ul {
  display: flex;
  flex-wrap: wrap;
}

.filterByServices ul li {
  font-size: 16px;
  line-height: 32px;
  color: #8AC04A;
  background-color: #e8f2db;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
border-radius: 3px;
display: flex;
  align-items: center;
cursor: pointer;
}

.filterByServices ul li:hover {
color: #ffffff;
  background-color: #8AC04A;
}

.filterByServices ul li.showMore {
  color: #388CEF;
  background-color: #d7e8fc;
}

.filterByServices ul li.showMore:hover {
  color: #ffffff;
  background-color: #388CEF;
}

.filterByServices ul li.clearAll {
  color: #F56666;
  background-color: #fde0e0;
}

.filterByServices ul li.clearAll:hover {
  color: #ffffff;
  background-color: #F56666;
}

.filterByServices ul li > * {
margin-left: 5px;
}

.filterByType ul {
display: flex;
flex-wrap: wrap;
}

.filterByType ul li.clearAll {
color: #F56666;
background-color: #FDE0E0;
}

.filterByType ul li.clearAll:hover {
color: #ffffff;
background-color: #F56666;
}

.filterByType ul li.clearAll .fa-times-circle {
font-size: 24px;
}

.filterByType ul li {
  position: relative;
color: #8AC04A;
  background-color: #E8F2DB;
  border-radius: 3px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
cursor: pointer;
}

.filterByType ul li:hover {
color: #ffffff;
  background-color: #8AC04A;
}

.filterByType ul li:not(:last-child) {
margin-right: 10px;
}

.filterByType ul li > div {
text-align: center;
}

.filterByType ul li span {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: block;
margin-top: 5px;
}

.filterByType ul li:not(.clearAll) svg:nth-child(1) {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 14px;
}

.filterByType ul li svg:nth-child(2) {
margin-top: 10px;
}

@media screen and (min-width: 1024px) {
  
.fileIconContainer {
  margin-right: 15px;
}

}

@media screen and (min-width: 1100px) {

.servicesFilter span {
  line-height: 32px;
}

.fileList .fileListHeader > ul > li {
  font-size: 20px;
  line-height: 28px;
}

}


@media screen and (min-width: 1200px) {

.fileList .fileListCol >  li:nth-child(1) {
  width: 3%;
}
.fileList .fileListCol >  li:nth-child(2) {
  width: 25%;
}
.fileList .fileListCol >  li:nth-child(3) {
  width: 10%;
}
.fileList .fileListCol >  li:nth-child(4) {
  width: 12%;
}
.fileList .fileListCol >  li:nth-child(5) {
  width: 12%;
}
.fileList .fileListCol >  li:nth-child(6) {
  width: 18%;
}
.fileList .fileListCol >  li:nth-child(7) {
  width: 15%;
}
.fileList .fileListCol >  li:last-child {
  width: 5%;
}	

}
