.modalContainer { 
    background-color: #FFFFFF; 
    border: 1px solid red; 
    width: 100%; 
    max-width: 700px; 
    position: relative; 
    padding: 35px 50px; 
    box-sizing: border-box; 
} 

.closeModal { 
    font-weight: 900; 
    font-size: 16px; 
    line-height: 18px; 
    color: #F56666; 
    background-color: rgb(245 102 102 / 12%); 
    border: unset; 
    outline: none; 
    width: 35px; 
    height: 35px; 
    position: absolute; 
    top: 0; 
    right: 0; 
	cursor: pointer; 
} 

.closeModal:hover { 
    color: #ffffff; 
    background-color: #F56666; 
} 

.modalTitle h1 { 
    font-size: 20px; 
    line-height: 32px; 
    text-align: center; 
    color: #000000; 
    margin: 0; 
} 

.search { 
    background: #FFFFFF; 
    border: 1px solid #EBEBEB; 
    box-sizing: border-box; 
    border-radius: 3px; 
    padding: 2px 4px 2px 2px; 
    width: 330px; 
	margin: 25px auto 25px auto; 
	display: flex; 
    align-items: center; 
    justify-content: space-between; 
} 

::placeholder { 
	color: #B0B0B0; 
} 

.search input { 
    font-size: 16px; 
    line-height: 10px; 
    color: #000000; 
    padding: 10px 15px; 
    border: unset; 
	outline: none; 
	width: 100%; 
} 

.search button { 
    height: 33px; 
    width: 33px; 
	color: #ffffff; 
	background: #388CEF; 
    border: unset; 
    border-radius: 1px; 
    cursor: pointer; 
	outline: none; 
} 

.search button:hover { 
	opacity: .8; 
} 

.modalContent ul { 
    list-style: none; 
    margin: 0; 
    padding: 0 15px 0 0; 
	max-height: 440px; 
    overflow-x: auto; 
} 

.modalContent ul::-webkit-scrollbar { 
	width: 20px; 
	background: #F9F9F9; 
	border: 1px solid #EBEBEB; 
	box-sizing: border-box; 
	border-radius: 3px; 
} 

.modalContent ul::-webkit-scrollbar-thumb { 
	background: #FFFFFF; 
	border: 1px solid #EBEBEB; 
	box-sizing: border-box; 
	border-radius: 3px; 
} 

.modalContent ul li { 
    font-size: 16px; 
    line-height: 32px; 
    color: #B0B0B0; 
    background: #F9F9F9; 
    border: 1px solid #EBEBEB; 
    box-sizing: border-box; 
    border-radius: 3px; 
    padding: 10px 15px; 
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
	cursor: pointer; 
} 

.modalContent ul li:not(:last-child) { 
	margin-bottom: 10px; 
} 

 .modalContent ul li.selected,
 .modalContent ul li:hover {
    color: #ffffff;
    background: #F56666;
    border: 1px solid #EA5252;
}

.modalContent ul li > span { 
    display: flex; 
    align-items: center; 
	margin-left: 10px; 
} 

.checkbox { 
	display: inline-block; 
	position: relative; 
	cursor: pointer; 
	-webkit-user-select: none; 
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
	margin-left: 16px; 
} 

.checkbox input { 
	display: none; 
} 

 .modalContent .checkmark {
	border: 1px solid #EBEBEB;
	background-color: #ffffff;
	box-sizing: border-box;
	border-radius: 3px;
	width: 30px;
	height: 30px;
	display: inline-block;
}

 .modalContent ul li.selected .checkmark,
 .modalContent  ul li:hover .checkmark {
	background-color: #ffffff;
	border: 1px solid #EA5252;
}

.checkmark:after { 
  content: ""; 
  position: absolute; 
  display: none; 
} 

.checkbox input ~ .checkmark:after { 
  display: block; 
} 

 .modalContent .checkbox .checkmark:after {
    left: 11px;
    top: 6px;
    width: 5px;
    height: 12px;
	border: solid #E7E5E5;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

 .modalContent  ul li.selected .checkmark:after,
 .modalContent  ul li:hover .checkmark:after {
    border: solid #F56666;
    border-width: 0 3px 3px 0;
}

.actionBtn { 
	text-align: center; 
	margin-top: 25px; 
} 

 .modalContent  .actionBtn button {
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: #ffffff;
	background: #EA5252;
	border-radius: 3px;
	border: none;
	padding: 15px 30px;
	cursor: pointer;
}

.actionBtn button:hover { 
	opacity: .8; 
} 