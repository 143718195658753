body {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background-color: #e5e5e5;
}

.select-color {
  background-color: #8ac04a !important;
}

.ui.mini.modal {
  width: auto !important;
}

.ui.modal {
  background: transparent !important;
  box-shadow: none !important;
}

.pretty.remember_me .state label:before {
  background: #c8c8c8 !important;
  border: none !important;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  height: 5px !important;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: 150px !important;
  text-align: initial;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 1px;
}

.react-pdf__Page__textContent {
  padding: 20px !important;
}

.react-pdf__Document {
  padding-left: 1rem;
  padding-left: 1rem;
  padding-right: 0rem;
}

.PDF_optionsContaineClass__12hLQ {
  margin-bottom: 0.5rem !important;
}

.react-pdf_Page_canvas {
  display: block;
  user-select: none;
  width: 595px;
  height: 850px;
}

.draggable-container {
  height: calc(100vh - 50px);
  width: calc(100vh - 100px);
  padding: 20px;
}

.box {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: auto;
  height: auto;
  margin: 10px;
  padding: 10px;
  float: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.mr-2 {
  margin-right: 0.5rem;
}

.headerRow {
  padding: 0 5px;
}

.headerRow,
.portalFooter .footerRow {
  max-width: 1440px;
  margin: auto;
}

.header .topHeader {
  background-color: #222222;
  padding: 0 5px;
  position: relative;
}

.topHeader .topHeaderMenu {
  justify-content: flex-end;
  width: 100%;
}

.topHeader .topHeaderMenu > ul > li:not(:last-child):not(:nth-last-child(2)) {
  padding: 17px 15px;
  cursor: pointer;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)):hover {
  background-color: #222222;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) * {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.topHeader .topHeaderMenu ul li .messages {
  font-size: 13px !important;
  color: #ffffff !important;
  background-color: #ff5f5f;
  border-radius: 2px;
  padding: 2px 6px;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) svg {
  margin-right: 10px;
}

.mobileMenu {
  display: flex;
  justify-content: flex-end;
}

.mobileMenu .menuBars {
  width: 60px;
  height: 60px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.mobileMenu .menuBars .bars {
  position: relative;
  width: 30px;
  height: 22px;
  border-top: 3px solid;
  border-bottom: 3px solid;
  transition: all 0.2s ease;
  margin-right: 0 !important;
}

.mobileMenu .menuBars .bars:after,
.mobileMenu .menuBars .bars:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #222222;
  transition: all 0.2s ease;
}

.mobileMenu .menuBars.on .bars:before {
  transform: rotate(45deg);
}

.mobileMenu .menuBars.on .bars:after {
  transform: rotate(-45deg);
}

.mobileMenu .menuBars.on .bars {
  border-color: transparent;
}

.bottomHeader ul li:hover .sub-menu {
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}

.bottomHeader .mainHeaderMenu li .sub-menu {
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.5s cubic-bezier(0.2, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;
  width: 250px;
  position: absolute;
  top: 30px;
  left: 0;
  background-color: #8ac04a;
  z-index: 99;
}

.bottomHeader .mainHeaderMobileMenu li .sub-menu {
  background-color: #f9f9f9;
  display: none;
}

.bottomHeader .mainHeaderMenu li .sub-menu {
  padding: 10px 0;
}

.bottomHeader ul li .sub-menu {
  list-style: none;
  margin: 0;
}

.bottomHeader ul li .sub-menu li a {
  font-size: 17px;
  line-height: 32px;
  color: #ffffff;
  padding: 5px 15px;
  display: flex;
  align-items: center;
}

.bottomHeader ul li .sub-menu li a:hover {
  opacity: 0.8;
}

.bottomHeader ul li .sub-menu li:not(:last-child) a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bottomHeader ul li .sub-menu li a > * {
  margin-right: 8px;
}

.topHeader .userIcon {
  background-color: #8bc334;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 0.625rem;
}

.topHeader .userIcon svg {
  color: #ffffff;
}

.topHeader .topHeaderMenu > ul > li:nth-last-child(2) {
  padding: 5px 25px 5px 5px;
  border-radius: 22px;
}

.topHeader .services {
  border-radius: 3px;
  padding: 5px 10px;
}

.topHeader .services > span:last-child svg {
  margin-left: 5px;
}

.topHeader .topHeaderMenu > ul > li:last-child,
.topHeader .topHeaderMenu > ul > li:nth-last-child(2),
.topHeader .services {
  background: rgba(255, 255, 255, 0.2);
}

.topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *,
.topHeader .services * {
  font-size: 13px;
}

.topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *:first-child,
.topHeader .topHeader .topHeaderMenu ul li:last-child svg,
.services *:first-child {
  color: #ffffff;
  line-height: 1.5;
}

.topHeader .topHeaderMenu > ul > li:nth-last-child(2) .userInfo *:last-child,
.topHeader .topHeaderMenu > ul > li:last-child,
.topHeader .services *:last-child {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  line-height: 1;
}

.topHeader .topHeaderMenu > ul > li:last-child {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-left: 5px;
}

.topHeader .topHeaderMenu ul li:last-child svg {
  color: #ffffff;
  font-size: 17px;
}

.header .bottomHeader {
  background-color: #ffffff;
  padding: 15px 0;
  position: relative;
}

.bottomHeader .headerMenu .mainHeaderMenu {
  display: none;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
  font-size: 17px;
  text-transform: uppercase;
  color: #222222;
  padding: 10px 15px;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem.active .menuItemText,
.bottomHeader .headerMenu .mainHeaderMenu .menuItem:hover .menuItemText {
  color: #ffffff;
  background-color: #8ac04a;
  border-radius: 3px;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem:not(:last-child) {
  margin-right: 5px;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem {
  position: relative;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .sub-menu-container:hover
  .menuItemText {
  border-radius: 3px 3px 0 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu {
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  z-index: 9;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.5s cubic-bezier(0.2, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu.slideDown {
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .sub-menu-container.active {
  padding: 0;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  > .sub-menu-container.active
  > a {
  margin: 15px 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .sub-menu-container > a {
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .sub-menu-container > a svg {
  margin-left: 5px;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li {
  border-top: 1px solid #ebebeb;
  padding: 15px 0;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  > .sub-menu-container
  .sub-menu
  li {
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  > .sub-menu-container
  .sub-menu
  a {
  padding: 25px 15px;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li a {
  font-size: 20px;
  display: inline-block;
  padding: 10px 15px;
  color: #222222;
  text-transform: uppercase;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.active > a,
.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > a:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border-radius: 3px;
}

.portalFooter h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 30px;
}

.portalFooter .footerTop {
  background-color: #222222;
}

.portalFooter .footerTop .footerRow {
  padding: 35px 50px 45px 50px;
}

.products .product {
  width: 32%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.products .product:not(:nth-last-child(-n + 3)) {
  margin-bottom: 16px;
}

.products .product svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
}

.products .product span {
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.4);
}

.products .product a {
  font-size: 16px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 5px;
}

.products .product.commingSoon a {
  background-color: #ff8888;
}

.products .product.visit a {
  background-color: #8ac04a;
}

.products .product.subscribed a {
  background-color: #8ac04a;
}

.products .product.subscribed.active {
  background-color: #8ac04a;
}

.products .product.subscribed.active svg,
.products .product.subscribed.active span {
  color: #ffffff;
}

.products .product.subscribed.active a {
  color: #8ac04a;
  background-color: #ffffff;
}

.portalFooter .footerBottom {
  background-color: #000000;
}

.portalFooter .footerBottom .footerRow {
  padding: 15px 25px;
}

.footerBottom .footerMenu ul li:not(:last-child) {
  margin-right: 15px;
}

.footerBottom .footerCopyright span,
.footerBottom .footerMenu li .footerMenuText {
  font-size: 16px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.6);
}

.contentHeader {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  width: 100%;
}

.topHeaderContainer.links-page {
  margin-bottom: 10px;
}

.topHeaderContainer {
  margin-bottom: 30px;
  padding-left: 30px;
}

.searchBar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #222222;
  padding: 40px;
  z-index: 9;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.2s ease;
}

.searchBar.on {
  transform: translateY(0);
  opacity: 1;
}

.searchBar .searchRow {
  padding: 2px 4px 2px 2px;
  width: 600px;
  /* margin: auto; */
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
}

.searchBar input {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  padding: 10px 10px 10px 25px;
  border: unset;
  outline: none;
  width: 100%;
  background-color: unset;
}

.searchBar button {
  font-size: 18px;
  height: 48px;
  width: 48px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.closeSearch {
  width: 48px;
  height: 48px;
  font-size: 18px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.logo {
  max-width: 230px;
}

.logo img {
  width: 100%;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

.webinar-global-toggle .ant-switch-checked {
  background: #8bc334 !important;
}

.webinar-global-toggle .ant-switch {
  width: 117px !important;
  height: 60px !important;
}

.webinar-global-toggle .ant-switch-handle {
  top: 4px;
  width: 52px !important;
  height: 52px !important;
  border-radius: 9999px;
  overflow: hidden;
}

.webinar-global-toggle .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 52px - 2px);
}

@media screen and (min-width: 1024px) {
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: flex;
  }

  .mobileMenu {
    display: none;
  }

  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 12px;
  }
}

@media screen and (min-width: 1150px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 15px;
  }
}

@media screen and (min-width: 1225px) {
  .products .product {
    width: 15.8%;
  }

  .products .product:not(:nth-last-child(-n + 3)) {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1270px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
    font-size: 17px;
  }
}

@media screen and (max-width: 1150px) {
  .portalFooter .footerMenu {
    width: 65%;
  }

  .portalFooter .footerCopyright {
    width: 35%;
    text-align: right;
  }
}
