.main-content {
  max-width: 1440px;
  margin: 30px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;
}

.contactDetails {
  display: flex;
  flex-wrap: wrap;
}

.contactDetails a {
  font-size: 16px;
  text-transform: uppercase;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 125px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactDetails a:hover {
  color: #ffffff;
  background-color: #8bc334;
  border: 1px solid #8bc334;
}

.contactDetails a:first-child {
  margin-bottom: 10px;
}

.contactDetails a > * {
  margin-right: 8px;
}

.search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  width: 330px;
}

.search input {
  font-size: 16px;
  line-height: 10px;
  /* color: #B0B0B0; */
  padding: 10px 15px;
  border: unset;
  outline: none;
}

.search button {
  height: 33px;
  width: 33px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.search button:hover {
  opacity: 0.8;
}

.pagination {
  padding: 30px 30px 0 30px;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}

.pagination a {
  font-size: 16px;
  width: 68px;
  height: 60px;
  color: #222222;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.documentsList {
  background-color: #ffffff;
  padding-bottom: 30px;
}

.filterContainer {
  padding: 60px 35px 35px;
  /* display: none; */
}

.searchActions {
  /* max-width: 710px; */
  margin-bottom: 25px;
}

.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  display: block;
}

.actions button {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px 15px;
  cursor: pointer;
}

.actions button > * {
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
}

.actions button:not(:last-child) {
  margin-right: 5px;
}

.actions button:hover {
  color: #ffffff;
  background-color: #8ac04a;
}

.actions button:hover > * {
  color: #ffffff;
}

.actions button svg {
  margin-right: 8px;
}

.searchContainer {
  max-width: 330px;
  text-align: left;
}

/* .searchContainer span { */
/* font-weight: 400; */
/* font-size: 16px; */
/* line-height: 32px; */
/* color: #000000; */
/* } */

.servicesFilter ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
}

/* .servicesFilter .label { */
/* color: #000000; */
/* } */

.servicesFilter ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 25px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.servicesFilter ul li svg {
  fill: #b0b0b0;
  width: 45px;
}

.servicesFilter ul li:hover,
.servicesFilter ul .active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.servicesFilter ul li:hover svg,
.servicesFilter ul .active svg {
  fill: #ffffff;
}

.servicesFilter ul li span {
  display: flex;
  margin-right: 15px;
}

.fileList .fileListCol {
  display: flex;
  padding: 20px;
}

.fileList .fileListHeader .fileListCol {
  background: #388cef;
  align-items: center;
}

.fileList .fileListBody .fileListCol {
  border-bottom: 1px solid #e7e7e7;
}

.fileList .fileListCol > li:nth-child(1) {
  width: 20%;
}

.fileList .fileListCol > li:nth-child(2) {
  width: 25%;
}

.fileList .fileListCol > li:nth-child(3) {
  width: 25%;
}

.fileList .fileListCol > li:nth-child(4) {
  width: 25%;
}

/* .fileList .fileListCol > li:nth-child(5) {
width: 15%;
}

.fileList .fileListCol > li:nth-child(6) {
  width: 15%;
  }

.fileList .fileListCol > li:nth-child(7) {
    width: 10%;
} */

.fileList .fileListCol > li:last-child {
  width: 5%;
  height: 50px;
  /* text-align: right; */
  position: relative;
}

.fileList .fileListHeader > ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}

.fileList .fileListHeader > ul > li.filterActive .serviceTypeFilter {
  display: block;
}

.fileList .fileListHeader > ul > li > svg {
  margin-left: 4px;
}

.fileList .fileListHeader > ul > li .serviceTypeFilter {
  position: absolute;
  top: 100%;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 3px;
  width: 210px;
  z-index: 9;
  /* display: none; */
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li.selected {
  background-color: #96c65c;
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li:hover {
  opacity: 0.8;
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li svg {
  color: rgb(0 0 0 / 20%);
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li.selected svg {
  color: #ffffff;
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li:not(:last-child) {
  border-bottom: 1px solid #7bae3e;
}

.fileList .fileListHeader > ul > li .serviceTypeFilter li button {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  background: rgb(255 255 255 / 20%);
  border: unset;
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.fileList .fileListBody .fileListCol > li {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #7d7d7d;
}
.fileList .fileListBody .fileListCol > li:not(:last-child) {
  padding-right: 10px;
}

/* .fileList .fileListCol > li:not(:last-child) {
padding-right: 10px;
} */

.fileList .fileListHeader .fileListCol > li:last-child button {
  color: #ffffff;
  background: #449aff;
  border: 1px solid #1e73d6;
}

.fileList .fileListCol > li:last-child button {
  font-size: 19px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

/* .fileList .fileListHeader .fileListCol > li:last-child button:hover, */
/* .fileList .fileListHeader .fileListCol > li:last-child button.active { */
/* color: #449AFF; */
/* background: #ffffff; */
/* border: 1px solid #ffffff; */
/* } */

.fileList .fileListBody .fileListCol > li:last-child button:hover,
.fileList .fileListBody .fileListCol > li:last-child button.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.fileList .fileListCol > li:last-child button.active {
  border-radius: 3px 3px 0 0;
}

.fileList .fileListCol > li:last-child button.active ~ ul {
  display: block;
}

.fileList .fileListCol > li:last-child .settingMenu {
  position: absolute;
  top: 95%;
  right: 0;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
  box-sizing: border-box;
  border-radius: 3px 0px 3px 3px;
  width: 200px;
  /* display: none; */
  z-index: 9;
}

.fileList .fileListCol > li:last-child .settingMenu li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.fileList .fileListCol > li:last-child .settingMenu li:hover {
  opacity: 0.8;
}

.fileList .fileListCol > li:last-child .settingMenu li:not(:last-child) a {
  border-bottom: 1px solid #7bae3e;
}

.fileList .fileListCol > li:last-child .settingMenu li a > * {
  margin-right: 8px;
}

.fileList .serviceName {
  display: flex;
}

.fileList .fileIcon img {
  width: 36px;
}

.fileList .fileIconContainer {
  margin-right: 10px;
}

.fileList .fileIcon {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileList .fileIcon svg {
  fill: #b0b0b0;
  width: 36px;
}

.fileList .fileListBody ul.selected .fileIcon,
.fileList .fileListBody ul:hover .fileIcon {
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.fileList .fileListBody ul.selected .fileIcon svg,
.fileList .fileListBody ul:hover .fileIcon svg {
  fill: #ffffff;
}

.fileList .fileContent span:first-child {
  font-weight: 400;
  line-height: 28px;
  color: #000000;
}
.fileList .fileContent span {
  display: block;
  word-break: break-word;
}

.fileList .checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fileList .fileListHeader .checkmark {
  border: 1px solid #1e73d6;
}

.fileList .checkmark {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.fileList .fileListHeader .checkbox:hover .checkmark,
.fileList .fileListHeader .checkbox.selectedAll .checkmark {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.fileList .fileListHeader .checkbox .checkmark {
  background-color: #2e82e3;
}

.fileList .fileListBody .selected .checkbox .checkmark,
.fileList .fileListBody .fileListCol:hover .checkbox .checkmark {
  background-color: #388cef;
}

.fileList .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.fileList .fileListBody > ul:hover,
.fileList .fileListBody > ul.selected {
  background-color: rgba(56, 140, 239, 0.05);
  cursor: pointer;
}

.fileList .checkbox .checkmark:after {
  display: block;
}

.fileList .checkbox .checkmark:after {
  left: 11px;
  top: 6px;
  width: 5px;
  height: 12px;
  border: solid #e7e5e5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fileList .fileListHeader .checkbox.selectedAll .checkmark:after,
.fileList .fileListHeader .checkbox:hover .checkmark:after {
  border: solid #388cef;
  border-width: 0 3px 3px 0;
}

.fileList .fileListHeader .checkbox .checkmark:after {
  border: solid #479cff;
  border-width: 0 3px 3px 0;
}

.fileList .fileListBody .fileListCol:hover .checkmark:after,
.fileList .fileListBody .selected .checkmark:after {
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
}

@media screen and (min-width: 1024px) {
  .fileList .fileIconContainer {
    margin-right: 20px;
  }
  .fileList .fileListHeader > ul li {
    font-size: 20px;
    line-height: 28px;
  }
  .fileList .fileListBody .fileListCol > li {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1100px) {
  .servicesFilter ul li {
    padding: 25px;
  }

  .fileList .fileListHeader > ul > li {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1150px) {
}

@media screen and (min-width: 1225px) {
}

@media screen and (min-width: 1270px) {
}

@media screen and (min-width: 1440px) {
  .fileList .fileListCol > li:nth-child(1) {
    width: 20%;
  }

  .fileList .fileListCol > li:nth-child(2) {
    width: 25%;
  }

  .fileList .fileListCol > li:nth-child(3) {
    width: 25%;
  }

  .fileList .fileListCol > li:nth-child(4) {
    width: 25%;
  }

  .fileList .fileListCol > li:nth-child(5) {
    width: 5%;
  }

  /* .fileList .fileListCol > li:nth-child(6) {
    width: 15%;
    }
  
  .fileList .fileListCol > li:nth-child(7) {
      width: 10%;
  } */
}
