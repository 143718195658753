.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContainer {
  height: 494px;
  margin: 7.5rem 4rem 0;
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.spinnerDiv {
  margin-top: 30vh;
  text-align: center;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  height: 32px;
  /* identical to box height, or 128% */

  display: flex;
  align-items: center;

  color: #000000;
}

.lightText {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-style: normal;
}

.bottomMargin {
  margin-bottom: 3rem;
}

.cardLeft,
.cardRight {
  height: 494px;
  width: 450px;
}

.checkTextGroup svg {
  margin-top: 0.2rem;
}

.checkTextGroup {
  display: flex;
  margin-bottom: 1rem;
}

.checkText {
  font-size: 16px;
  margin-left: 5px;
  line-height: 19px;
  font-weight: normal;
}

.buttonContainer {
  margin-top: 2.5rem;
  align-self: flex-start;
}

.inputGroup {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.inputBox {
  font-size: 20px;
  padding: 16px 8px;
  margin-top: 0.5rem;
  border-radius: 5px;
  border: 1px solid black;
}

.loginButton {
  /* padding: 1.3rem 4rem; */
  width: auto;
  height: 50px;
  align-self: flex-start;
  background: #f1f8e7;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #8bc334 !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  font-style: normal;
  text-align: center;
  outline: none;
}

.loginButton:hover {
  color: #ffffff !important;
  background: #8bc334;
}

.forgotPasswordGroup {
  color: #f56666;
  display: flex;
  align-items: center;
}

.forgotPasswordGroup:hover {
  opacity: 0.7;
}

.forgotPasswordIcon {
  font-size: 30px !important;
  color: #f56666 !important;
}

.forgotPasswordText {
  color: #f56666;
  margin-left: 5px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  text-decoration-line: underline;
}

.forgotPasswordText:hover {
  color: rgb(245 102 102 / 0.8);
  text-decoration: underline;
}

.inputField {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
}

@media screen and (min-width: 992px) {
  .headerText {
    font-size: 25px;
  }
  .cardContainer {
    /* width: 900px; */
    width: 450px;
  }
}

@media screen and (max-width: 992px) {
  .cardContainer {
    width: 450px;
  }
}

@media screen and (min-width: 1024px) {
  .cardContainer {
    margin: 7.5rem 0 0;
  }
  /* .cardLeft {
    padding: 4.5rem 4rem 5rem 3.5rem;
  } */
}
