.moduleTypeBtns {
  display: flex;
  column-gap: 5px;
}

.moduleTypeBtns button {
  font-size: 16px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moduleTypeBtns button svg {
  margin-right: 5px;
  margin-bottom: 3px;
}

.moduleTypeBtns button.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.inputGroup label {
  font-weight: 400;
  color: #272828;
}

.inputGroup input,
.inputGroup textarea {
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 14px 20px;
}

.blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.blueButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.filePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 90px;
  background: #f9f9f9;
  border: 1px dashed #e0e0e0;
}

.filePicker p {
  color: #7d7d7d;
  opacity: 0.4;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 10px;
}

.fileIcon {
  width: 50px;
  height: 50px;
  display: grid;
  color: #ffffff;
}

.fileIcon div {
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.removeFile {
  color: #f56666;
  background-color: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  line-height: 16px;
}

.removeFile:hover {
  color: #ffffff;
  background-color: #f56666;
}

.supportText > * {
  font-family: "Rubik", sans-serif;
}

.supportText h3 {
  font-size: 20px;
  color: #272828;
  margin-bottom: 10px;
}

.supportText p {
  font-size: 16px;
  color: #7d7d7d;
}

.addFile h3 {
  font-size: 20px;
  color: #272828;
  margin-bottom: auto;
  margin-top: auto;
}

div.fileHint > p {
  margin: 0px;
}
