.modal { 
    position: fixed; 
    top: 0; 
    bottom: 0; 
    right: 0; 
    left: 0; 
    padding-top: 100px; 
    padding-bottom: 100px; 
    width: 100%; 
    height: unset; 
    overflow: auto; 
    background-color: rgb(235 235 235 / .9); 
    display: none; 
    z-index: 9999999; 
} 

.modalContainer.medium { 
    background-color: #FFFFFF; 
    width: 100%; 
    max-width: 700px; 
    margin: auto; 
    position: relative; 
    padding: 35px 50px; 
    box-sizing: border-box; 
} 

.modalContainer .closeModal { 
    font-weight: 900; 
    font-size: 16px; 
    line-height: 18px; 
    color: #F56666; 
    background-color: rgb(245 102 102 / 12%); 
    border: unset; 
    outline: none; 
    width: 35px; 
    height: 35px; 
    position: absolute; 
    top: 0; 
    right: 0; 
    cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
} 

.modalContainer .closeModal:hover { 
    color: #ffffff; 
    background-color: #F56666; 
} 

.modalContainer .modalTitle h1 { 
    font-weight: 400; 
    font-size: 20px; 
    line-height: 32px; 
    text-align: center; 
    color: #000000; 
    margin: 0; 
} 

.modalContainer .search { 
    background: #FFFFFF; 
    border: 1px solid #EBEBEB; 
    box-sizing: border-box; 
    border-radius: 3px; 
    padding: 2px 4px 2px 2px; 
    width: 330px; 
    margin: 25px auto 25px auto; 
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
} 

.modalContainer ::placeholder { 
    color: #B0B0B0; 
} 

.modalContainer .search input { 
    font-size: 16px; 
    line-height: 10px; 
    color: #000000; 
    padding: 10px 15px; 
    border: unset; 
    outline: none; 
    width: 100%; 
} 

.modalContainer .search button { 
    height: 33px; 
    width: 33px; 
    color: #ffffff; 
    background: #388CEF; 
    border: unset; 
    border-radius: 1px; 
    cursor: pointer; 
    outline: none; 
} 

.modalContainer .search button:hover { 
    opacity: .8; 
} 

.modalContent ul { 
    list-style: none; 
    margin: 0; 
    padding: 0 15px 0 0; 
    max-height: 440px; 
    overflow-x: auto; 
} 

.modalContent ul::-webkit-scrollbar { 
    width: 20px; 
    background: #F9F9F9; 
    border: 1px solid #EBEBEB; 
    box-sizing: border-box; 
    border-radius: 3px; 
} 

.modalContent ul::-webkit-scrollbar-thumb { 
    background: #FFFFFF; 
    border: 1px solid #EBEBEB; 
    box-sizing: border-box; 
    border-radius: 3px; 
} 

.modalContent ul::-webkit-scrollbar-thumb:hover { 
    background: #8AC04A; 
    border: 1px solid #7BAE3E; 
} 

.modalContent ul li { 
    font-size: 16px; 
    line-height: 32px; 
    color: #B0B0B0; 
    background: #F9F9F9; 
    border: 1px solid #EBEBEB; 
    box-sizing: border-box; 
    border-radius: 3px; 
    padding: 10px 15px; 
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    cursor: pointer; 
} 

.modalContent ul li:not(:last-child) { 
    margin-bottom: 10px; 
} 

.modalContent ul li.selected,
.modalContent ul li:hover {
   color: #ffffff;
   background: #8AC04A;
   border: 1px solid #7BAE3E;
}

.modalContent ul li > span {
   display: flex;
   align-items: center;
   margin-left: 10px;
}

.modalContainer .checkbox {
   display: inline-block;
   position: relative;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   margin-left: 16px;
}

.modalContainer .checkbox input {
   display: none;
}

.modalContainer .checkmark {
   border: 1px solid #EBEBEB;
   background-color: #ffffff;
   box-sizing: border-box;
   border-radius: 3px;
   width: 30px;
   height: 30px;
   display: inline-block;
}

.modalContent ul li.selected .checkmark,
.modalContent ul li:hover .checkmark {
   background-color: #ffffff;
   border: 1px solid #7BAE3E;
}

.modalContainer .checkmark:after {
 content: "";
 position: absolute;
 display: none;
}

.modalContainer .checkbox input ~ .checkmark:after {
 display: block;
}

.modalContainer .checkbox .checkmark:after {
   left: 13px;
   top: 9px;
   width: 5px;
   height: 12px;
   border: solid #E7E5E5;
   border-width: 0 3px 3px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
}

.modalContent ul li.selected .checkmark:after,
.modalContent ul li:hover .checkmark:after {
   border: solid #8AC04A;
   border-width: 0 3px 3px 0;
}

.modalContainer .actionBtn { 
    text-align: center; 
    margin-top: 20px; 
} 

.modalContainer.medium .actionBtn button { 
    font-size: 16px; 
    line-height: 19px; 
    text-transform: uppercase; 
    color: #F9F9F9; 
    background: #388CEF; 
    border-radius: 3px; 
    border: none; 
    padding: 15px 30px; 
    cursor: pointer; 
} 

.modalContainer .actionBtn button:hover {
   opacity: .8;
}