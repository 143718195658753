.pagination {
    padding: 30px 30px 0 30px;
    }
    
    .pagination a:not(:last-child) {
      margin-right: 10px;
    }
    
    .pagination a {
    font-size: 16px;
      width: 68px;
      height: 60px;
    color: #222222;
      background-color: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
      border-radius: 5px;
    }
    
    .pagination a:hover,
    .pagination a.active {
    color: #ffffff;
    background-color: #8AC04A;
    border: 1px solid #8AC04A;
    }