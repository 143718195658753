.mainContent{
    max-width: 1440px;
    margin: 30px auto 25px auto;
    padding-right: 20px;
    padding-left: 20px;
}

.topHeaderContainer {
	margin-bottom: 30px;
	padding-left: 30px;
}

.contentHeader {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    color: #000000;
    width: 100%;
}