.modal { 
    position: fixed; 
    top: 0; 
    bottom: 0; 
    right: 0; 
    left: 0; 
    padding-top: 100px; 
    padding-bottom: 100px; 
    width: 100%; 
    height: unset; 
    overflow: auto; 
    background-color: rgb(235 235 235 / .9); 
    display: none; 
    z-index: 9999999; 
} 

.modalContainer.medium { 
    background-color: #FFFFFF; 
    width: 100%; 
    max-width: 700px; 
	margin: auto; 
    position: relative; 
    padding: 35px 50px; 
    box-sizing: border-box; 
} 

.closeModal { 
    font-weight: 900; 
    font-size: 16px; 
    line-height: 18px; 
    color: #F56666; 
    background-color: rgb(245 102 102 / 12%); 
    border: unset; 
    outline: none; 
    width: 35px; 
    height: 35px; 
    position: absolute; 
    top: 0; 
    right: 0; 
	cursor: pointer; 
    display: flex;
    align-items: center;
    justify-content: center;
} 

.closeModal:hover { 
    color: #ffffff; 
    background-color: #F56666; 
} 

.modalTitle {
	margin-bottom: 35px;
	text-align: center;
}

.modalTitle h1 { 
    font-size: 20px; 
    line-height: 32px; 
    color: #000000; 
    margin: 0; 
} 

.modalTitle p {
	font-weight: 300;
	font-size: 16px;
	line-height: 25px;
	color: #7D7D7D;
	margin-top: 10px;
	margin-bottom: 0;
}


.modalContent ul { 
    list-style: none; 
    margin: 0 0 25px 0; 
    padding: 0; 
} 

.modalContent ul li {
	color: #B0B0B0; 
	background: #F9F9F9; 
	border: 1px solid #EBEBEB; 
	box-sizing: border-box; 
	border-radius: 3px; 
	padding: 22px 25px;
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    cursor: pointer; 
}

.modalContent ul li:not(:last-child) { 
	margin-bottom: 10px; 
} 

 .modalContent ul li .collectionInfo {
    display: flex;
	align-items:center;
}

.modalContent ul li .collectionInfo svg {
	fill: #B0B0B0;
	width: 45px;
}

 .modalContent ul li .collectionInfo span {
	margin-left: 15px;
}

.modalContent ul li:hover,
.modalContent ul li.active {
	color: #ffffff;
	background: #8AC04A;
	border: 1px solid #7BAE3E;
}

 .modalContent ul li:hover .collectionInfo svg,
 .modalContent ul li.active .collectionInfo svg {
	fill: #ffffff;
}

.modalContent ul li span { 
    font-weight: 400; 
    font-size: 16px; 
    line-height: 32px; 
    display: block; 
} 

.modalContainer .checkbox {
	display: inline-block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-left: 16px;
}

.modalContainer .checkbox input {
	display: none;
}

.modalContainer .checkmark {
	border: 1px solid #EBEBEB;
	background-color: #ffffff;
	box-sizing: border-box;
	border-radius: 3px;
	width: 30px;
	height: 30px;
	display: inline-block;
}

.modalContent ul li.active .checkmark,
.modalContent ul li:hover .checkmark {
	background-color: #ffffff;
	border: 1px solid #7BAE3E;
}

.modalContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.modalContainer .checkbox input ~ .checkmark:after {
  display: block;
}

.modalContainer .checkbox .checkmark:after {
    left: 13px;
    top: 9px;
    width: 5px;
    height: 12px;
	border: solid #E7E5E5;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.modalContent ul li.active .checkmark:after,
.modalContent ul li:hover .checkmark:after {
    border: solid #8AC04A;
    border-width: 0 3px 3px 0;
}

.actionBtn { 
	text-align: center; 
} 

.actionBtn button { 
	font-size: 16px; 
	line-height: 19px; 
	text-transform: uppercase; 
	color: #F9F9F9; 
	background: #388CEF; 
	border-radius: 3px; 
	border: none; 
	padding: 15px 30px; 
	cursor: pointer; 
} 

.actionBtn button:hover { 
	opacity: .8; 
} 