body {
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	padding: 0;
	margin: 0;
	background-color: #E5E5E5;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

a {
	text-decoration: none;
}

.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}

.items-center {
	align-items: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-center {
	justify-content: center;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.text-center {
	text-align: center;
}

ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.mr-2 {
	margin-right: 0.5rem;
}

.bottomHeader .headerRow {
	padding: 15px 20px;
	box-sizing: border-box;
}

.topHeader .headerRow {
	padding: 8px 20px;	
	box-sizing: border-box;
	justify-content: flex-end;
}

.headerRow,
.portalFooter .footerRow {
    max-width: 1440px;
    margin: auto;
}

.header .topHeader {
	background-color: #222222;
	position: relative; 
}

.topHeader .topHeaderMenu li:not(:last-child) {
    margin-right: 20px;
}

.topHeader .topHeaderMenu li a {
    font-size: 16px;
    line-height: 32px;
    color: rgb(255 255 255 / 60%);
}

.topHeader .topHeaderMenu li a:hover {
	color: #ffffff;
}

.topHeader .headerButtons {
    display: flex;
    align-items: center;
	margin-left: 25px;
}

/* .topHeader .LogoutBtn { */
	/* margin-right: 8px; */
/* } */

.topHeader .headerButtons button:hover {
	color: #ffffff;
}

.topHeader .LogoutBtn button {
    font-size: 16px;
    color: rgb(255 255 255 / 60%);
    background-color: rgb(255 255 255 / 20%);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    padding: 0 12px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topHeader .searchBtn button {
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: rgb(255 255 255 / 60%);
    background-color: rgb(255 255 255 / 20%);
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

/* .topHeader .topHeaderMenu { */
    /* justify-content: flex-end; */
    /* width: 100%; */
/* } */

/* .topHeader .topHeaderMenu > ul > li:not(:last-child):not(:nth-last-child(2)) { */
	/* padding: 17px 15px; */
        /* cursor: pointer; */
	
/* } */

/* .topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)):hover { */
	/* background-color: #222222; */
/* } */

/* .topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) * { */
	/* font-size: 16px; */
	/* color: rgb(255 255 255 / 60%); */
/* } */

/* .topHeader .topHeaderMenu ul li .messages { */
	/* font-size: 13px !important; */
    /* color: #ffffff !important; */
    /* background-color: #FF5F5F; */
    /* border-radius: 2px; */
    /* padding: 2px 6px; */
/* } */

/* .topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) svg { */
    /* margin-right: 10px; */
/* } */

.mobileMenu {
    display: flex;
    justify-content: flex-end;
}

.mobileMenu .menuBars {
    width: 60px;
    height: 60px;
    background: #F9F9F9;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
	outline: none;
}

.mobileMenu .menuBars .bars {
    position: relative;
    width: 30px;
    height: 22px;
    border-top: 3px solid;
    border-bottom: 3px solid;
    transition: all 0.2s ease;
    margin-right: 0 !important;
}

.mobileMenu .menuBars .bars:after, 
.mobileMenu .menuBars .bars:before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #222222;
    transition: all 0.2s ease;
}

.mobileMenu .menuBars.on .bars:before {
    transform: rotate(45deg);
}

.mobileMenu .menuBars.on .bars:after {
    transform: rotate(-45deg);
}

.mobileMenu .menuBars.on .bars {
    border-color: transparent;
}


.bottomHeader .mainHeaderMenu li.active .subMenu {
    clip: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.bottomHeader .mainHeaderMenu li .subMenu {
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0.1;
    transition: transform .5s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
    transform: translateY(-10px) scaleY(0.5);
    transform-origin: top;
    width: 250px;
    position: absolute;
    top: 55px;
    left: 0;
    background-color: #222222;
    z-index: 99;
}

.bottomHeader .mainHeaderMobileMenu li .subMenu {
    background-color: #f9f9f9;
	display: none;
}

/* .bottomHeader .mainHeaderMenu li .subMenu { */
	/* padding: 10px 0; */
/* } */

.bottomHeader ul li .subMenu {
    list-style: none;
    margin: 0;
}

.bottomHeader ul li .subMenu li span {
    font-size: 17px;
    line-height: 32px;
    color: #FFFFFF;
    padding: 15px;
    display: flex;
    align-items: center;
}

.bottomHeader ul li .subMenu li span:hover {
	opacity: .8;
}

.bottomHeader ul li .subMenu li:not(:last-child) span {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.bottomHeader .mobileMenu ul li .subMenu li span > * {
	color: #222222;
}

.bottomHeader ul li .subMenu li span > * {
	color: rgb(255 255 255 / 40%);
    margin-right: 8px;
}

/* .topHeader .userIcon { */
	/* background-color: #8BC334; */
	/* width: 32px; */
	/* height: 32px; */
	/* border-radius: 50%; */
	/* margin-right: 0.625rem; */
/* } */

/* .topHeader .userIcon svg { */
	/* color: #ffffff; */
/* } */

/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2) { */
	/* padding: 5px 25px 5px 5px; */
    /* border-radius: 22px; */
/* } */

/* .topHeader .services { */
	/* border-radius: 3px; */
	/* padding: 5px 10px; */
/* } */

/* .topHeader .services > span:last-child svg { */
	/* margin-left: 5px; */
/* } */

/* .topHeader .topHeaderMenu > ul > li:last-child, */
/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2), */
/* .topHeader .services { */
	/* background: rgb(255 255 255 / 20%); */
	
/* } */

/* .topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *, */
/* .topHeader .services * { */
	/* font-size: 13px; */
/* } */

/* .topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *:first-child, */
/* .topHeader .topHeader .topHeaderMenu ul li:last-child svg, */
/* .services *:first-child { */
	/* color: #ffffff; */
	/* line-height: 1.5;  */
/* } */

/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2) .userInfo *:last-child, */
/* .topHeader .topHeaderMenu > ul > li:last-child, */
/* .topHeader .services *:last-child { */
	/* color: rgb(255 255 255 / 60%); */
	/* cursor: pointer; */
	/* line-height: 1; */
/* } */

/* .topHeader .topHeaderMenu > ul > li:last-child { */
    /* width: 42px; */
    /* height: 42px; */
	/* border-radius: 50%; */
	/* margin-left: 5px; */
/* } */

/* .topHeader .topHeaderMenu ul li:last-child svg { */
	/* color: #ffffff; */
	/* font-size: 17px; */
/* } */

.header .bottomHeader {
	background-color: #ffffff;
	position: relative;
}

.bottomHeader .headerMenu .mainHeaderMenu {
	display: none;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
	font-size: 17px;
	text-transform: uppercase;
	color: #222222;
	padding: 10px 15px;
	cursor: pointer;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem.active .menuItemText,
.bottomHeader .headerMenu .mainHeaderMenu .menuItem:hover .menuItemText {
	color: #ffffff;
	background-color: #8AC04A;
	border-radius: 3px;;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem:not(:last-child) {
	margin-right: 5px;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem {
  position: relative;
}

/* .bottomHeader .headerMenu .mainHeaderMenu .subMenu-container:hover .menuItemText { */
      /* border-radius: 3px 3px 0 0; */
/* } */

.bottomHeader .mobileMenu .mainHeaderMobileMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    z-index: 9;
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0.1;
    transition: transform .5s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
    transform: translateY(-10px) scaleY(0.5);
    transform-origin: top;
	box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu.slideDown {
    clip: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .subMenuContainer.active {
	padding: 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .subMenuContainer.active > span {
	margin: 15px 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .subMenuContainer > span {
	padding: 10px 15px;
    display: inline-flex;
    align-items: center;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .subMenuContainer > a svg {
	margin-left: 5px;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li {
	border-top: 1px solid #EBEBEB;
	padding: 15px 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .subMenuContainer .subMenu li {
	border-bottom: 1px solid rgb(34 34 34 / 10%);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > .subMenuContainer .subMenu span {
	padding: 25px 15px;
    display: inline-flex;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li span {
    font-size: 20px;
    display: inline-block;
    padding: 10px 15px;
    color: #222222;
	text-transform: uppercase;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.active > span,
.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > span:hover {
    color: #ffffff;
    background-color: #8AC04A;
    border-radius: 3px;
}


.portalFooter h3 {
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	color: #FFFFFF;
	margin-bottom: 30px;
}

.portalFooter .footerTop {
	background-color: #222222;
}

.portalFooter .footerTop .footerRow {
	padding: 35px 50px 45px 50px;
	box-sizing: border-box;
}

.products .product {
	width: 32%;
	background: rgb(255 255 255 / 10%);
    border-radius: 3px;
    padding: 20px;
	position: relative;
	box-sizing: border-box;
}

.products .product:not(:nth-last-child(-n+3)) { 
	margin-bottom: 16px;
}

.products .product svg {
    position: absolute;
    top: 5px;
    right: 5px;
    color: rgb(255 255 255 / 40%);
	font-size: 14px;
}

.products .product span {
	font-size: 16px;
	line-height: 28px;
	color: rgb(255 255 255 / 40%);
}

.products .product a {
	font-size: 16px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 2px;	
	margin-top: 5px;
}

.products .product.commingSoon a {
	background-color: #FF8888;
}

.products .product.visit a {
	background-color: #8AC04A;
}

.products .product.subscribed a {
	background-color: #8AC04A;
}

.products .product.subscribed.active {
    background-color: #8AC04A;	
}

.products .product.subscribed.active svg,
.products .product.subscribed.active span {
	color: #ffffff;
}

.products .product.subscribed.active a {
	color: #8AC04A;
	background-color: #ffffff;
}

.portalFooter .footerBottom {
	background-color: #000000;
}

.footerBottom .footerMenu ul li:not(:last-child) {
	margin-right: 15px;
}

.footerBottom .footerCopyright span,
.footerBottom .footerMenu li .footerMenuText {
	font-size: 16px;
	line-height: 32px;
	color: rgb(255 255 255 / 60%);
}

.footerBottom .footerMenu li .footerMenuText:hover {
	color: #ffffff;
}

.contentHeader {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    color: #000000;
    width: 100%;
}

.topHeaderContainer.links-page {
	margin-bottom: 10px;
}

.topHeaderContainer {
	margin-bottom: 30px;
	padding-left: 30px;
}


.searchBar {
    position: absolute;
    top: 0;
	right: 0;
    left: 0;
    background-color: #222222;
    padding: 50px 40px;
    z-index: 9;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.2s ease;
}

.searchBar.on {
    transform: translateY(0);
    opacity: 1;
}

.searchBar .searchRow {
    padding: 2px 4px 2px 2px;
    width: 600px;
    /* margin: auto; */
    border: 1px solid rgb(255 255 255 / 20%);
    box-sizing: border-box;
    border-radius: 3px;
}

.searchBar input {
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
    padding: 10px 10px 10px 25px;
    border: unset;
    outline: none;
    width: 100%;
    background-color: unset;
}

.searchBar button {
    font-size: 18px;
    height: 48px;
    width: 48px;
    color: #ffffff;
    background: rgb(255 255 255 / 20%);
    border: unset;
    border-radius: 1px;
    cursor: pointer;
    outline: none;
}

.closeSearch {
    width: 48px;
    height: 48px;
    font-size: 18px;
    color: #ffffff;
    background-color: rgb(255 255 255 / 20%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;
}

.logo {
    max-width: 230px;
}

.logo img {
    width: 100%;
}

.headerMenu .mainHeaderMenu .settingMenu {
    width: 230px;
    }
    
    .headerMenu .mainHeaderMenu .settingMenu {
      position: absolute;
      top: 95%;
      right: 0;
      background-color: #8AC04A; 
      border: 1px solid #7BAE3E;
      box-sizing: border-box;
      /* border-radius: 3px 0px 3px 3px; */
      width: 165px;
    /* display: none; */
    z-index: 9;
    }
    
    .headerMenu .mainHeaderMenu  .settingMenu li .enableFile {
    display: none;
    }
    
    .headerMenu .mainHeaderMenu  .settingMenu li a {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #FFFFFF;
      padding: 5px 10px;
      display: flex; 
      align-items: center;
    }
    
    .headerMenu .mainHeaderMenu .settingMenu li:hover {
    opacity: .8;
    }
    
    .headerMenu .mainHeaderMenu .settingMenu li:not(:last-child) a {
    border-bottom: 1px solid #7BAE3E;
    }
    
    .headerMenu .mainHeaderMenu .settingMenu li a > * {
    font-size: 19px;
    margin-right: 8px;
    }

@media screen and (max-width: 1250px) {
	
	.portalFooter .footerBottom .footerRow {
		flex-wrap: wrap;
	}	
	
	.portalFooter .footerBottom .footerMenu {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px 0;
		border-bottom: 1px solid #ffffff;
	}	
	
	.portalFooter .footerBottom .footerCopyright {
		width: 100%;
		text-align: center;
		padding: 30px 0;
	}
	
}

@media screen and (min-width: 1024px) {
	
	.bottomHeader .headerMenu .mainHeaderMenu {
		display: flex;
	}
	
	.mobileMenu {
		display: none;
	}
	
	.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
		font-size: 12px;
	}	
	
}


@media screen and (min-width: 1150px) {
	
	.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
		font-size: 15px;
	}	
	
}

@media screen and (min-width: 1225px) {

	.products .product {
		width: 15.8%;
	}
	
	.products .product:not(:nth-last-child(-n+3)) { 
		margin-bottom: 0;
	}
	
}

@media screen and (min-width: 1251px) {
	
	
	.portalFooter .footerBottom .footerRow {
		padding: 15px 25px;
		box-sizing: border-box;
	}		
	
}

@media screen and (min-width: 1270px) {
	
	.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText {
		font-size: 17px;
	}	
	
}
